import Service from "./service"

class ProfileService extends Service {
    async getProfile() {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Profile`, requestOptions);
    }

    async getAccountInfo() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Info`, requestOptions);
    }
}

export default new ProfileService();
const initialState = {
  currentPage : ''
}

const reducer = (state = initialState, action) => {
  if (action.type === 'CURRENT_PAGE_CHANGED') {
    return {
      ...state,
      currentPage: action.currentPage
    }
  }

  return state;
}

export default reducer;
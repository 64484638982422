import React from 'react';
import CustomerReviewInfo from './CustomerReviewInfo';
import CustomerRatingService from '../../services/search-purchases-services/CustomerRatingService';
import { withSnackbar } from 'notistack';

//MUI
import { Box, Stack, Pagination } from '@mui/material';

class CustomerReviewsPanel extends React.Component {

  constructor(props) {
    super(props);  

    this.state = {
      pageNumber: 0,
      pageSize: 5,
      pageCount: 0,
      reviews: null
    };   

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handlePaginationChange(event, page) {
    this.updateReviews(page);
  }

  componentDidMount() {
    this.updateReviews(this.state.pageNumber);
  }

  updateReviews(page) {
    const customerOrganizationId = this.props.customerOrganizationId;
    if (customerOrganizationId) {
      CustomerRatingService.GetReviews({customerOrganizationId: customerOrganizationId, pageNumber: page, pageSize: this.state.pageSize})
        .then(result => {
          this.setState({ reviews: result.collections, pageCount: result.pageCount, pageNumber: result.pageNumber });
        })
        .catch(error => {
          this.props.enqueueSnackbar(
            'Произошла ошибка при получении списка отзывов по компании',
            { 
              variant: 'error'
            });
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.reviews 
          ? 
            this.state.reviews.map((review, key) =>
              <CustomerReviewInfo review={review} key={review.id}/>    
            )
          :
            ""
        }
        {this.state.reviews && this.state.reviews.length > 0
            ?
            <Box sx={{ mt: 2 }}>
              <Stack spacing={2}>
                <Pagination count={this.state.pageCount ? this.state.pageCount : 1} page={this.state.pageNumber ? this.state.pageNumber : 1} onChange={this.handlePaginationChange} />
              </Stack>
            </Box>
            : null}
      </React.Fragment>
    )
  }
}

export default withSnackbar(CustomerReviewsPanel);

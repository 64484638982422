import { MapKladrCodeFromCrmToSp } from './kladr-code-items-mapper'
import { DateTime } from 'luxon'

export const SearchFilterFromSpToCrm = (sp_filter) => {
    return {};
}

export const SearchFilterFromCrmToSp = (crm_filter) => {
    let stages = [];
    if (crm_filter.PurchaseStatusStarted) {
      stages.push(1);
    }
    if (crm_filter.PurchaseStatusInProgress) {
      stages.push(2);
    }
    if (crm_filter.PurchaseStatusCompleted) {
      stages.push(3);
      stages.push(4);
      stages.push(5);
    }

    let laws = [];
    if (crm_filter.Purchases44) {
      laws.push(1);
    }
    if (crm_filter.Purchases223) {
      laws.push(2);
    }
    if (crm_filter.Commercial) {
      laws.push(3);
    }
    if (crm_filter.Ea615Auctions) {
      laws.push(4);
    }

    let auctionTypes = [];
    if (crm_filter.EaAuctions) {
      auctionTypes.push(1);
    }
    if (crm_filter.ZkAuctions) {
      auctionTypes.push(3);
    }
    if (crm_filter.KoAuctions) {
      auctionTypes.push(4);
    }
    if (crm_filter.EpAuctions) {
      auctionTypes.push(5);
    }
    if (crm_filter.OtherAuctions) {
      auctionTypes.push(2);
      auctionTypes.push(6);
      auctionTypes.push(7);
    }

    let dateExpirationFrom = null;
    if (crm_filter.DaysLeftTillCollectingEnd) {
        dateExpirationFrom = DateTime.now().plus({days: crm_filter.DaysLeftTillCollectingEnd});
    }

    let keywords = [];
    if (crm_filter.Keywords) {
        keywords = crm_filter.Keywords.split(',').map((keyword) => keyword.trim());
    }

    let ignoreKeywords = [];
    if (crm_filter.ExcludeKeywords) {
        ignoreKeywords = crm_filter.ExcludeKeywords.split(',').map((keyword) => keyword.trim());
    }
 
    return {
      keywords: keywords,
      ignoreKeywords: ignoreKeywords,

      shouldSearchInDocuments: crm_filter.SearchKeywordsInDocuments,
      shouldSearchInOkpdNames: crm_filter.SearchKeywordsInOkpdNames,
      havePrepayment: crm_filter.HasPrepayment,
      // haveRecommendation: false,
      // datePublicationFrom: null,
      // datePublicationTo: null,
      dateExpirationFrom: dateExpirationFrom,
      priceFrom: crm_filter.MinContractPrice,
      priceTo: crm_filter.MaxContractPrice,
      stages: stages,
      laws: laws,
      auctionTypes: auctionTypes,
      kladrCodes: MapKladrCodeFromCrmToSp(crm_filter.KladrCodeItems),
      okpdCodes: crm_filter.topOkpdCodes,
      // industryCodes: [],
      shouldIncludeEmptyPrice: crm_filter.IncludeWithoutContractPrice,
    }
}
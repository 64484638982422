import React from "react"

export const InvoiceNoPayments = (props) => {
    return (
        <React.Fragment>
            <div className="payment_block">
                <div className="invoice_table_header">Платежи:</div>
                <div className="payment_table">
                        <div className="payment_row">
                            Платежей нет
                        </div>
                </div>
            </div>
		</React.Fragment>
    )
}

import Service from "../service";

class TemplateService extends Service {
  async getAll() {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    return await this.authenticatedFetch(`${baseURL}/api/purchases/templates`, requestOptions);
  }    

  async getById(id) {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    return await this.authenticatedFetch(`${baseURL}/api/purchases/templates/${id}`, requestOptions);
  }

  async add(newTemplate) {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      },
      body: JSON.stringify(newTemplate)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    return await this.authenticatedFetch(`${baseURL}/api/purchases/templates`, requestOptions);
  }

  async update(id, changeTemplate) {
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      },
      body: JSON.stringify(changeTemplate)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    return await this.authenticatedFetch(`${baseURL}/api/purchases/templates/${id}`, requestOptions);
  }

  async remove(id) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      }
    };

    //TODO: унифицировать, вероятно надо будет немного трогать эндпоинт на бэке
    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    return await fetch(`${baseURL}/api/purchases/templates/${id}`, requestOptions);
  }    
}

export default new TemplateService();
const initialState = {
  openSelectTagDialog: false,
  selectedPurchase: {},
  selectedTagId: '',
  newTagName: '',
  tags: [],
  newTagValidationErrors: [],
  isLoading: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'OPEN_TAG_DIALOG') {
    return {
      ...state,
      selectedPurchase: action.selectedPurchase,
      selectedTagId: action.selectedTagId,
      tags: action.tags,
      newTagName: '',
      openSelectTagDialog: true,
      newTagValidationErrors: []
    }
  }

  if (action.type === 'CLOSE_TAG_DIALOG') {
    return {
      ...state,
      openSelectTagDialog: false
    }
  }

  if (action.type === 'UPDATE_TAG_LIST') {
    return {
      ...state,
      tags: action.tags,
      newTagName: '',
      newTagValidationErrors: []
    } 
  }

  if (action.type === 'NEW_TAG_FIELD_CHANGED') {
    return {
      ...state, 
      newTagName: action.newTagName
    }
  }

  if (action.type === 'SET_IS_LOADING') {
    return {
      ...state,
      isLoading: true
    }
  }

  if (action.type === 'TOGGLE_PURCHASE_TAG') {
    let updatedTags = [];
    if (state.selectedPurchase.tags.some(tag => tag.id === action.newTag.id)) {
      updatedTags = [...state.selectedPurchase.tags.filter(tag => tag.id !== action.newTag.id)]
    } else {
      updatedTags = [...state.selectedPurchase.tags, { id: action.newTag.id, name: action.newTag.name }]
    }
    var updatedSelectedPurchase = { ...state.selectedPurchase, tags: updatedTags }
    return {
      ...state,
      selectedPurchase: updatedSelectedPurchase,
      isLoading: false
    }
  }

  if (action.type === 'NEW_TAG_ADD_ERROR_OCCURED'){
    return {
      ...state,
      newTagValidationErrors: action.newTagValidationErrors
    }
  }

  return state;
}

export default reducer;
import Service from "./service"

class ComplaintsService extends Service {
    async sendComplaint(complaint){
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.getAuthorizationToken()
        },
        body: JSON.stringify(complaint)
      };

      const response = await fetch(`${process.env.REACT_APP_PORTAL_API_URI}/Complaints`, requestOptions);

      if (response.status === 401) {
        window.location.replace("/signin");
      }

      if (response.status === 200){
        return;
      }

      const json = await response.json();
      throw json.Message;
    }
}

export default new ComplaintsService();
import React, { useState, useEffect } from 'react';
import UserManager from '../../userManager';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container,  } from '@mui/material';

export const SigninCallback = () => {
	const [haveError, setHaveError] = useState(false);
	const [haveIncorrectTime, setHaveIncorrectTime] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		UserManager.clearStaleState();
		UserManager.signinRedirectCallback()
		.then(user => {
			navigate("/");
		})
		.catch((error) => {
			setHaveError(true);
			if (error.message.indexOf("iat is in the future") >= 0) {
				setHaveIncorrectTime(true);
			}
		});
	}, [])
	
	if (haveError) {
		return (
			<React.Fragment>
				<Container maxWidth="md">
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							border: '1px'
						}}
					>
						{
							<Typography>
								Произошла ошибка при попытке входа.
							</Typography>
						}
						{
							haveIncorrectTime 
							?
								<>
									<Typography>
										Вероятно, у Вас на компьютере неверно указаны либо время, либо временная зона.
									</Typography>
								</>
							:
								null
						}
						{/* <Typography>
							Можете связаться с нами по контактам: бла-бла-бла
						</Typography> */}
					</Box>
				</Container>
			</React.Fragment>
		)
	} else {
		return null;
	}
}
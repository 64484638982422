import React, { useState } from "react"
import { Button, Box, TextField } from "@mui/material"
import { useSnackbar } from "notistack";
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import InputMask from 'react-input-mask'
import { useDispatch } from "react-redux";

import { phone_number_validation_rule, phoneValidationRegex } from "../../constants/validation_rules";
import { phoneTokenPurposeTypes } from "../../constants/phone_token_purpose_types";
import { PhoneTokenInputModal } from "../authentication/phone_token_input_modal";

import settingsService from "../../services/settingsService";
import profileService from "../../services/profileService";

export const UpdateUserInfoPhoneNumber = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ showPhoneTokenInputModal, setShowPhoneTokenInputModal] = useState(false);
    const [ inputValues, setInputValues ] = useState(null);
    const dispatch = useDispatch();

    const handlePhoneNumberUpdate = async (formValues) => {
        const request = {
            TargetPhoneNumber: formValues.phone_number,
            Token: formValues.token
        };
        return settingsService.sendUpdatePhoneNumber(request)
            .then(() => {
                enqueueSnackbar('Номер телефона успешно изменен', { variant: "success" });
                profileService.getAccountInfo()
                    .then((userInfo) => {
                        setInputValues('');
                        const action = {
                            type: 'USER_INFO_OBTAINED',
                            currentUserInfo: {
                                phone_number: userInfo.PhoneNumber,
                                phone_confirmed: userInfo.PhoneNumberConfirmed,
                                email: userInfo.Email,
                                email_confirmed: userInfo.EmailConfirmed,
                                id: userInfo.Id
                            }
                        };
                        dispatch(action);
                    })
                    .catch((error) => {
                        this.props.enqueueSnackbar(`Произошла ошибка при получении данных аккаунта. ${error.Message ? error.Message : ''}`, { variant: "error" });
                    })
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            });
    }

    const validationSchema = Yup.object({
        phone_number: phone_number_validation_rule
            .required('Обязательное поле')
            .test('checkPhoneAvailable', 'Номер телефона уже зарегистрирован в системе', async (value) => {
                if (value && !value.match(phoneValidationRegex)) {
                    return true;
                }
                const result = await settingsService.checkPhoneAvailability(value);
                return result.isOk;
            }),
    });

    const handleInputToken = (token) => {
        const request = {...inputValues, token};
        handlePhoneNumberUpdate(request)
            .then(() => {
                setShowPhoneTokenInputModal(false);
            })
    }

    const handleSendToken = (phone_number) => {
        settingsService.sendPhoneConfirmation(phone_number, phoneTokenPurposeTypes.forPhoneChanging)
        .then((result) => {
            setShowPhoneTokenInputModal(true);
            enqueueSnackbar('Успешно отправлено смс с кодом', { variant: "success" });
        })
        .catch((error) => {
            enqueueSnackbar(`Произошла ошибка при отправке смс, ${error.Message}`, { variant: "error" });
        });
    }

    const handleModalClose = () => {
        setShowPhoneTokenInputModal(false);
    }

    return (
        <React.Fragment>
            <PhoneTokenInputModal open={showPhoneTokenInputModal} inputToken={handleInputToken} resendToken={handleSendToken} onClose={handleModalClose} />
            <Box>
                <Formik 
                    initialValues={{phone_number: ''}}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setInputValues(values);
                        handleSendToken(values.phone_number);
                        setShowPhoneTokenInputModal(true);
                        setSubmitting(false);
                        resetForm();
                    }}
                >
                { formik => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Field name="phone_number">
                            {({field, form, meta}) => (
                                <InputMask
                                    {...field}
                                    mask="+7 999 999 9999"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (value){
                                            formik.setFieldValue('phone_number', value.replaceAll(' ',''));
                                        }
                                        else {
                                            formik.setFieldValue('phone_number', '');
                                        }
                                    }}
                                    onBlur={(event) => {
                                        //set field empty and reset touched when not match mask
                                        if (!meta.value.match(phoneValidationRegex))
                                        {
                                            formik.setFieldValue('phone_number', '');
                                            form.setTouched({});
                                        }  
                                        else {
                                            formik.handleBlur(event);
                                        }
                                    }}
                                >
                                {(inputProps) => 
                                    <TextField
                                        className="settings_form_input"
                                        {...inputProps}
                                        margin="normal"
                                        name="phone_number"
                                        label="Номер телефона"
                                        helperText={ meta.touched && meta.error}
                                        error={ meta.touched && meta.error != null }
                                        fullWidth
                                    />
                                }
                                </InputMask>
                            )}
                        </Field>

                        <Button sx={{mt: 2}}
                            type="submit"
                            variant="contained"
                            className="settings_form_submit"
                            disabled={formik.isSubmitting}
                        >
                            Сменить номер телефона
                        </Button>
                    </Form>
                )}
                </Formik>
            </Box>
        </React.Fragment>
    )
}

import * as Yup from "yup";

export const phone_number_validation_rule = Yup.string()
  .matches(/^\+?[78]\d{10}$/, 'Должен быть указан корректный номер телефона');

export const email_validation_rule = Yup.string()
  .email('Должен быть указан корректный e-mail');

export const password_validation_rule = Yup.string()
  .min(6, "Пароль должен быть не менее 6 символов")
  .matches('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'Пароль обязательно должен содержать прописныe/строчные буквы и цифры');

const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const phoneRegex = /^\+?[78]\d{10}$/;

export const phone_or_email_validation_rule = Yup.string()
  .test('email-or-phone-test', 'В качестве логина должен быть корректный e-mail или номер телефона',
      (value) => {
        const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let isValidEmail = emailRegex.test(value);

        const phoneRegex = /^\+?[78]\d{10}$/;
        let isValidPhone = phoneRegex.test(value);
        
        if (!isValidEmail && !isValidPhone ){
            return false;
        }
        return true;
      });

export const phoneValidationRegex = phoneRegex;

export const emailValidationRegex = emailRegex;

import React, { useState, useEffect } from "react"
import { CircularProgress } from "@mui/material"
import { connect } from 'react-redux';
import { RemoveCompanyModal } from "./remove-company-modal";
import { EditCompanyModal } from "./edit-company-modal";
import { useSnackbar } from "notistack";

import companiesService from "../../services/companiesService";

const CompanyItem = (props) => {
    const [isAllowedToRemove, setIsAllowedToRemove] = useState(false);
    const [isAllowedToEdit, setIsAllowedToEdit] = useState(false);
    const [showRemoveCompanyModal, setShowRemoveCompanyModal] = useState(false);
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setIsLoading(true);
        const checkAllowedToRemovePromise = companiesService.isCompanyAllowedToRemove(props.company.Id)
            .then((result) => {
                setIsAllowedToRemove(result);
            })
        const checkAllowedToEditPromise = companiesService.isCompanyAllowedToEdit(props.company.Id)
            .then((result) => {
                setIsAllowedToEdit(result);
            })
        Promise.all([checkAllowedToEditPromise, checkAllowedToRemovePromise])
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                //enqueueSnackbar(`Не удалось получить информацию по компании. ${error.Message ? error.Message : ''}`, { variant: "error" });
                setIsLoading(false);
            })
    },[props.company]);
    
    const handleRemoveCompanyButtonClick = () => {
        props.onRemove({ AcvId: props.company.Id }, isAllowedToRemove)
    }

    const handleRemoveCompanyWithRequestButtonClick = () => {
        setShowRemoveCompanyModal(true);
    }

    const handleRemoveCompanyModalSubmit = (company) => {
        setShowRemoveCompanyModal(false);
        props.onRemove(company);
    }

    const handleRemoveCompanyModalClose = () => {
        setShowRemoveCompanyModal(false);
    }

    const handleEditCompanyButtonClick = () => {
        setShowEditCompanyModal(true);
    }

    const handleEditCompanyModalSubmit = (company) => {
        setShowEditCompanyModal(false);
        props.onEdit(company, isAllowedToEdit);
    }

    const handleEditCompanyModalClose = () => {
        setShowEditCompanyModal(false);
    }

    const handleSelectingCurrentCompany = () => {
        localStorage.setItem(props.selectedCompanyStorageName, JSON.stringify({Id: props.company.Id }));
        props.onSelectingCurrentCompany(props.company);
        enqueueSnackbar('Компания успешно выбрана', { variant: "success" });
    }

    //TODO проверку новой/старой компании на случай когда компанию при втором этапе регистрации не выбрали, а потом добавили через настройки можно сделать поэлегантнее
    const selected = props.currentCompany ? props.company.Id === props.currentCompany.Id && props.company.Status === props.currentCompany.Status : false;
    return (
        <React.Fragment>
            {isLoading
                ?   
                    <div className="one_company_progress">
                        <CircularProgress />
                    </div>
                :
                <>
                    <RemoveCompanyModal open={showRemoveCompanyModal} onClose={handleRemoveCompanyModalClose} onSubmit={handleRemoveCompanyModalSubmit} company={props.company} />
                    <EditCompanyModal open={showEditCompanyModal} onClose={handleEditCompanyModalClose} onSubmit={handleEditCompanyModalSubmit} company={props.company} allowedToEdit={isAllowedToEdit} />
               
                    <div className={`one_company_row ${selected === true ? 'selected' : null}`}>
                        {selected === true
                            ?
                                <div variant="contained" color="success" disabled className="switcher_btn current_btn"></div>
                            :
                                <div 
                                    variant="contained" 
                                    color="success"
                                    onClick={handleSelectingCurrentCompany}
                                    className="switcher_btn"
                                >
                                    
                                </div>
                        }          

                        <div className="company_name" onClick={handleSelectingCurrentCompany}>
                            {props.company.Name}, ИНН: {props.company.TaxPayerNumber}
                        </div>

                        {isAllowedToEdit 
                        ?
                            <div 
                                variant="contained" 
                                color="info"
                                onClick={handleEditCompanyButtonClick}
                                className="company_icon edit_icon"
                            >
                                <div className="disclamer">
                                    Редактировать без запроса
                                </div>
                            </div>
                        :
                            <div 
                                variant="contained" 
                                color="info"
                                onClick={handleEditCompanyButtonClick}
                                className="company_icon edit_icon"
                            >
                                <div className="disclamer">
                                    Редактировать с запросом
                                </div>
                            </div>
                        }
                        {isAllowedToRemove
                        ?
                            <div 
                                variant="contained" 
                                color="error"
                                onClick={handleRemoveCompanyButtonClick}
                                className="company_icon delete_icon"
                            >
                                <div className="disclamer">
                                    Удалить без запроса
                                </div>
                            </div>
                        :
                            <div 
                                variant="contained" 
                                color="error"
                                onClick={handleRemoveCompanyWithRequestButtonClick}
                                className="company_icon delete_icon"
                            >
                                <div className="disclamer">
                                    Удалить с запросом
                                </div>
                            </div>
                        }
                    </div>
                </>
        }
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentCompany: state.profileState.currentCompany,
        currentUserInfo: state.profileState.currentUserInfo,
        selectedCompanyStorageName: state.profileState.selectedCompanyStorageName
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSelectingCurrentCompany: (selectedCompany) => {
            const action = {
                type: 'CURRENT_COMPANY_CHANGED',
                currentCompany: selectedCompany
            };
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyItem)

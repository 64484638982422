import Service from "./service";
import axios from "axios";

class FilesService extends Service {
    async getFile(fileUrl){
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': this.getAuthorizationToken()
        }
      };

      const response = await fetch(fileUrl, requestOptions);

      if (response.status !== 200) {
        throw response.message;
      }

      return response;
    }

    async getProtectedImage(fileUrl) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': this.getAuthorizationToken()
        }
      };

      const response = await fetch(fileUrl, requestOptions); 

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      return objectUrl;
    }

    async uploadFile(urlWithoutHost, file, onUploadProgress, onUploadSuccess, onUploadFailure) {
      let formData = new FormData();

      formData.append("file", file);

      axios.request({
        method: "post",
        url: `${process.env.REACT_APP_PORTAL_API_URI}${urlWithoutHost}`,
        data: formData,
        onUploadProgress: onUploadProgress,
        headers: {
          'Authorization': this.getAuthorizationToken(),
        },
      })
      .then((result) => {
        if (result.data.IsOk === false) {
          throw { response: result };
        }
        return result;
      })
      .then(onUploadSuccess)
      .catch(onUploadFailure)
    }
}

export default new FilesService();


import htmlParse from 'html-react-parser';

const initialState = {
  OkpdCodes: [],
  checkedOkpdCodes: [],
  openOkpdSelectionModal: false,
  expandedOkpdCodes: [],
  expandAll: false,
  isLoading: false,
  searchWarningMessage: '',
  isSearching: false
}

//Рекурсивно ищет в списке узлов nodes узел с nodeId и добавляет ему указанных children
const getNodeChildren = (nodeId, nodes, childrenNodes) => {
  return nodes.map((node) => {
    if (node.children) {
      getNodeChildren(nodeId, node.children, childrenNodes);
    }
    if (node.key === nodeId) {
      node.title = htmlParse(node.title);
      node.children = childrenNodes.map((childrenNode) => {
        if (node.checked === true){
          childrenNode.checked = node.checked;
        }
        return childrenNode;
      });
    };
    return node;
  })
}

//возвращает массив key всех входящих в дерево элементов
const getAllBranchCodes = (nodes) => {
  let codes = [];
  nodes.forEach(node => {
    if (node.children.length > 0) {
      getAllBranchCodes(node.children).forEach(node => codes.push(node));
      codes.push(node.key);
    };
    node.title = htmlParse(node.title);
  });
  return codes;
} 

const reducer = (state = initialState, action) => {
  if (action.type === 'OKPD_INITIATED') {
    var updatedOkpdCodes = [...action.OkpdCodes];
    return {
      ...state,
      OkpdCodes: updatedOkpdCodes,
      expandedOkpdCodes: [],
      searchWarningMessage: '',
      isSearching: false
    }
  }

  if (action.type === 'OKPD_EXPAND_CHILD_NODE') {
    let parentCode = action.parentCode;
    let childrenNodes = action.childrenNodes;

    let updatedChildrenNodes = childrenNodes.map((node) => {
      if (node.children.length === 0) {
        node.isLeaf = true;
      }
      return node;
    })

    let updatedOkpdCodes = getNodeChildren(parentCode, state.OkpdCodes, updatedChildrenNodes);
    
    return {
      ...state,
      OkpdCodes: updatedOkpdCodes
    }
  }

  if (action.type === 'OKPD_SELECTION_OPENED') {
    return {
      ...state,
      checkedOkpdCodes: action.checkedOkpdCodes,
      openOkpdSelectionModal: true
    }
  }

  if (action.type === 'OKPD_SELECTION_CLOSED') {
    return {
      ...state,
      openOkpdSelectionModal: false
    }
  }

  if (action.type === 'OKPD_SELECTION_COMPLETED') {
    return {
      ...state,
      openOkpdSelectionModal: false
    }
  }

  if (action.type === 'OKPD_CODE_CHECKED') {
    let updatedCheckedOkpdCodes = [];

    let positions = [...action.checkedOkpdNodesPositions];

    //when not search set checked only top parents
    if (state.isSearching === false) {
      let iterator = 0;
      while (positions[iterator]) {
        const currentPos = positions[iterator].pos + '-';

        //remove all descendant checked elements
        //so only parent elements left
        positions = positions.filter((element) => {
          if (element.pos.startsWith(currentPos) === false)
          {
            return element;
          }
        });
        iterator++;
      }

      updatedCheckedOkpdCodes = positions.map((element, key) => {
        return {
          key: element.node.key,
          children: element.node.children
        };
      })
    } else {
    //when search set checked only checked elements
      if (action.checkedNode.checked === false) {
        let newNode = {
          key: action.checkedNode.key,
          children: action.childrenNodes
        };
        updatedCheckedOkpdCodes = state.checkedOkpdCodes.concat(newNode);
      }
      if (action.checkedNode.checked === true) {
        const value = action.checkedNode.key;
        const array = state.checkedOkpdCodes.map(code => {
          return code.key;
        });
        updatedCheckedOkpdCodes = [...state.checkedOkpdCodes];
        const index = array.indexOf(value);
        if (index !== -1) {
          updatedCheckedOkpdCodes.splice(index, 1);
        }
      }
    }
    return {
      ...state,
      checkedOkpdCodes: updatedCheckedOkpdCodes
    }
  }

  if (action.type === 'SINGLE_SELECTED_OKPD_REMOVED' || action.type === 'TEMPLATES_SINGLE_SELECTED_OKPD_REMOVED') {
    const value = action.key;
    const array = state.checkedOkpdCodes.map(code => {
      return code.key;
    });
    const index = array.indexOf(value);
    const updatedCheckedOkpdCodes = [...state.checkedOkpdCodes];
    if (index !== -1) {
      updatedCheckedOkpdCodes.splice(index, 1);
      return {
        ...state,
        checkedOkpdCodes: updatedCheckedOkpdCodes
      }
    }
  }

  if (action.type === 'OKPD_SEARCH_STARTED'){
    return {
      ...state,
      isLoading: true,
      searchWarningMessage: ''
    }
  }

  if (action.type === 'OKPD_SEARCHED') {
    let expandedCodes = []
    if (action.searchResults.okpdCodes.length > 0) {
      expandedCodes = getAllBranchCodes(action.searchResults.okpdCodes);
    }

    let warningMessage = '';
    //TODO мб эти 50 вывести в env variables
    if (action.searchResults.count >= 50) {
      warningMessage = "Отображаются только первые 50 поисковых результатов"
    }
    if (action.searchText.length < 3 && action.searchResults.count == 0) {
      warningMessage = "Для поиска по тексту необходимо ввести минимум 3 символа"
    }

    return {
      ...state,
      OkpdCodes: action.searchResults.okpdCodes,
      expandedOkpdCodes: expandedCodes,
      searchWarningMessage: warningMessage,
      isLoading: false,
      isSearching: action.searchText.length
    }
  }

  if (action.type === 'OKPD_EXPANDED') {
    return {
      ...state,
      expandedOkpdCodes: action.expandedOkpdCode
    }
  }

  if (action.type === 'SINGLE_SELECTED_OKPD_REMOVED') {
    const value = action.key;
    const array = state.checkedOkpdCodes.slice();
    const deletedOkpd = array.find(okpd => okpd.key === value);
    const index = array.indexOf(deletedOkpd);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        checkedOkpdCodes: array
      }
    }
  }

  return state;
}

export default reducer;
import Service from "./service"

class MailingsService extends Service {
    async getMailings(acvId) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Mailings?acvId=${acvId}`, requestOptions);
    }

    async subscribe(mailingId) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*'
        },
        body: `{ Id: "${mailingId}" }`
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Mailings/Enable`, requestOptions);
    }

    async unsubscribe(mailingId) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*'
        },
        body: `{ Id: "${mailingId}" }`
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Mailings/Disable`, requestOptions);
    }

    async remove(mailingId) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: `{ Id: "${mailingId}" }`
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Mailings/Remove`, requestOptions);
    }

    async add(acvId, mailing) {
      mailing.AcvId = acvId;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(mailing)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Mailings/Add`, requestOptions);
    }

    async update(acvId, mailing) {
      mailing.AcvId = acvId;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(mailing)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Mailings/Update`, requestOptions);
    }
}

export default new MailingsService();
//Этапы закупок
export const purchaseStages = [
    {
        id: 1,
        mappedIds: [1],
        name: "Подача заявок"
    },
    {
        id: 2,
        mappedIds: [2],
        name: "Работа комиссии"
    },
    {
        id: 3,
        mappedIds: [3,5,7],
        name: "Завершены"
    },
    {
        id: 4,
        mappedIds: [4],
        name: "Отменены"
    },
    // {
    //     id: 5,
    //     name: "Не состоялись"
    // },
    // {
    //     id: 6,
    //     name: "Планируются"
    // },
    // {
    //     id: 7,
    //     name: "На исполнении"
    // }
]

import Service from "./service"

class LiveChatService extends Service {
    async GetChatId(){
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/LiveChat/ChatId`, requestOptions);
    }

    async SaveChatId(options){
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(options)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/LiveChat/ChatId`, requestOptions);
    }

    async GetSecretHash() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/LiveChat/SecretHash`, requestOptions);
    }
}

export default new LiveChatService();
const initialState = {
  tags: [],
  purchases: [],
  selectedTagId: null,
  selectedTag: {},
  editTagDialogOpened: false,
  editTagDialogErrors: [],
  editTag: {},
  editTagId: null,
  isTagsLoading: false,
  isPurchasesLoading: false,
  openSelectTagDialog: false,
  selectedPurchase: {},
  newTagName: ''
}

const reducer = (state = initialState, action) => {
  if (action.type === 'TAG_SELECTED') {
    return {
      ...state,
      purchases: [],
      isPurchasesLoading: true
    }
  }

  if (action.type === 'TAGS_SEARCH_STARTED') {
    return {
      ...state,
      isTagsLoading: true
    }
  }

  if (action.type === 'TAGS_SEARCH_FAILED') {
    return {
      ...state, 
      isTagsLoading: false,
      //error: action.error
    }
  }

  if (action.type === 'TAGS_FOUND') {
    return {
      ...state,
      tags: action.tags,
      isTagsLoading: false
    }
  }

  if (action.type === 'PURCHASES_FOR_TAG_FOUND') {
    let updatedPurchases = action.purchases.filter(purchase => purchase !== null)

    return {
      ...state,
      purchases: updatedPurchases,
      selectedTagId: action.selectedTagId,
      isPurchasesLoading: false
    }
  }

  if (action.type === 'ERROR_WHILE_GET_PURCHASES_FOR_TAG') {
    return {
      ...state,
      isPurchasesLoading: false
    }
  }

  if (action.type === 'PURCHASE_TAG_REMOVED') {
    let newPurchases = state.purchases.filter(item => item.id !== action.purchaseId);
    return {
      ...state,
      purchases: newPurchases
    }
  }

  if (action.type === 'TAG_REMOVED') {
    let newTags = state.tags.filter(item => item.id !== action.tagId)
    return {
      ...state,
      tags: newTags,
      selectedTagId: null,
      purchases: []
    }
  }

  if (action.type === 'EDIT_DIALOG_OPEN') {
    return {
      ...state,
      editTagDialogOpened: true,
      editTag: action.editTag,
      editTagId: action.editTagId,
      editTagDialogErrors: []
    }
  }

  if (action.type === 'EDIT_DIALOG_CANCEL') {
    return {
      ...state,
      editTagDialogOpened: false,
      editTag: {},
      editTagId: null
    }
  }

  if (action.type === 'ADD_DIALOG_SAVE') {
    var newTags = [...state.tags, action.tag];
    return {
      ...state,
      tags: newTags,
      editTagDialogOpened: false,
      editTagDialogErrors: [],
      editTag: {},
      editTagId: null
    }
  }

  if (action.type === 'EDIT_DIALOG_SAVE') {
    var newTags = state.tags.map((item, index) => {
      if (item.id == action.tagId)
      {
        item.name = action.tag.name;
      }
      return {
        ...item
      }
    });
    return {
      ...state,
      tags: newTags,
      editTagDialogOpened: false,
      editTagDialogErrors: [],
      editTag: {},
      editTagId: null
    }
  }

  if (action.type === 'EDIT_DIALOG_ERROR') {
    return {
      ...state,
      editTagDialogErrors: action.errors
    }
  }

  if (action.type === 'TAG_NAME_EDIT') {
    state.editTag.name = action.tagName;
    return {
      ...state
    }
  }

  if (action.type === 'UPDATE_TAG_LIST') {
    return {
      ...state,
      tags: action.tags
    } 
  }

  if (action.type === 'REFRESH_PURCHASE_TAGS') {
    const newPurchases = state.purchases.filter(item => item.id !== action.purchase.id);
    return {
      ...state,
      purchases: newPurchases
    }
  }

  if (action.type === 'PURCHASE_IN_WORK_FOR_TAG_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let isInWork = false;
      let purchase = action.purchasesInWork.find(p => p.PurchaseNumber === item.number)
      if (purchase) {
        isInWork = true;
      }
      return {
        ...item,
        isInWork: isInWork
      }
    });

    return {
      ...state, 
      purchases: newPurchases
    }
  }

  return state;
}

export default reducer;
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {withSnackbar} from 'notistack';
import {Box} from '@mui/system';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {NumericFormat} from 'react-number-format';

import PurchaseSearchResult from './PurchaseSearchResult';
import TemplatesPanel from './TemplatesPanel';
import OkpdSelection from './OkpdSelection';
import IndustrySelection from './IndustrySelection'
import SaveTemplateModal from './SaveTemplateModal.js';
import {SettlementsSelection} from '../settlements-selection.js';
import {ConfirmationDialog} from "../confirmation-dialog";

import TemplateService from '../../services/search-purchases-services/TemplateService.js';
import PurchaseService from '../../services/search-purchases-services/PurchaseService';
import SettlementService from '../../services/search-purchases-services/SettlementService';
import PurchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';
import CustomerRatingService from '../../services/search-purchases-services/CustomerRatingService';
import purchasesInWorkService from '../../services/purchasesInWorkService';

import {purchaseStages} from '../../constants/purchase_stages.js';
import {auctionTypes} from '../../constants/auction_types.js';
import {lawTypes} from '../../constants/law_types.js';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ButtonWithWaitForCompletion } from '../button-with-wait-for-completion.js';
import {CustomerSelection} from "../customer-selection.js";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=' '
            valueIsNumericString
            decimalScale={0}
        />
    );
});

class PurchaseSearchPanel extends Component {


    constructor(props) {
        super(props);
        this.state = {
            settlementsInputTimeout: null,
            expandSearch: false,
            expandStage: true,
            expandSourcingMethod: true,
            expandBiddingType: true
        };

        this.handleStageChange = this.handleStageChange.bind(this);
        this.handleAuctionTypeChange = this.handleAuctionTypeChange.bind(this);
        this.handleDayOfWeekNotificationChange = this.handleDayOfWeekNotificationChange.bind(this);
        this.handlePurchaseTypeChange = this.handlePurchaseTypeChange.bind(this);
        this.handlePageSearch = this.handlePageSearch.bind(this);
    }

    

    componentDidMount() {
        console.log(`scrolled to ${this.props.scrollPosition}`);
        window.scrollTo(0, this.props.scrollPosition);
        this.props.onPageOpen();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCompany && this.props.currentCompany && this.props.currentCompany.Id !== prevProps.currentCompany.Id) {
            this.props.onSearchPurchases(this.props, this.props.pageNumber);
        }

        //rerun search when sortBy changed
        if (prevProps.sortBy !== this.props.sortBy || prevProps.ascending !== this.props.ascending) {
            this.props.onSearchPurchases(this.props, 0, false);
        }
    }

    handlePageSearch(pageNumber) {
        this.props.onSearchPurchases(this.props, pageNumber);
    }

    handleStageChange(event) {
        var isChecked = event.target.checked;
        var value = parseInt(event.target.value);

        if (isChecked) {
            this.props.onAddStage(value);
        } else {
            this.props.onRemoveStage(value);
        }
    }

    handleAuctionTypeChange(event) {
        var isChecked = event.target.checked;
        var value = parseInt(event.target.value);

        if (isChecked) {
            this.props.onAddAuctionType(value);
        } else {
            this.props.onRemoveAuctionType(value);
        }
    }

    handlePurchaseTypeChange(event) {
        var isChecked = event.target.checked;
        var value = parseInt(event.target.value);

        if (isChecked) {
            this.props.onAddLawChanges(value);
        } else {
            this.props.onRemoveLawChanges(value);
        }
    }

    handleDayOfWeekNotificationChange(event) {
        var isChecked = event.target.checked;
        var value = parseInt(event.target.value);

        if (isChecked) {
            this.props.onAddDayNotify(value);
        } else {
            this.props.onRemoveDayNotify(value);
        }
    }

    closeDialog = () => {
        this.props.onCloseTagDialog(this.props.selectedPurchase.id);
    }

    render() {
        return (
            <React.Fragment>
                <TemplatesPanel/>
                <SaveTemplateModal/>
                <OkpdSelection/>
                <IndustrySelection/>

                {window.innerWidth < 1290 ?
                    <React.Fragment>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                        }
                        }>
                            <div className="filter_grid_search filter_grid">

                                {this.props.templateId ?
                                    <div className='tempalte_inputs'>
                                        <div className='filter_block filter_template_name'>
                                            <div className='filter_block_header'>
                                                Наименование шаблона
                                            </div>
                                            <TextField
                                                id="keywords"
                                                variant="outlined"
                                                fullWidth
                                                onChange={(event) => this.props.onTemplateNameChanges(event.target.value)}
                                                value={this.props.name}
                                            />
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className='filter_grid1'>
                                    <div className='filter_block filter_keywords'>
                                        <div className='filter_block_header'>
                                            Ключевые слова
                                        </div>
                                        {
                                            window.innerWidth > 1290 ?
                                                <TextField
                                                    id="keywords"
                                                    variant="outlined"
                                                    placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                                    fullWidth
                                                    multiline
                                                    inputProps={{
                                                        style: {
                                                            padding: 0
                                                        }
                                                    }}
                                                    rows={3}
                                                    onChange={(event) => this.props.onKeywordsChanges(event.target.value)}
                                                    value={this.props.keywords.join(',')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment className="input_adornment_keywords"
                                                                            position="start">
                                                                <Tooltip
                                                                    title={
                                                                        <div style={{whiteSpace: 'pre-line'}}>
                                                                            {
                                                                                "Чтобы найти все закупки по нескольким ключевым словам, необходимо указать их через пробел или запятую.\r\n "+
                                                                                "Пробел — найдет закупки, которые содержат словосочетание в пределах 3-4 слов. (пример: строительные материалы — результат: материалы и конструкции строительные)\r\n \r\n "+
                                                                                "Запятая — найдет закупки, которые содержат одно из ключевых слов. Работает как «или». (пример: лес, дерево — результат: закупки где содержится ИЛИ лес ИЛИ дерево)\r\n \r\n "+ 
                                                                                "Дополнительно:\r\n * (символ звёздочки) заменит любое количество любых символов\r\n (пример: газ* — результат: газообразный, газель; пример: запрос бе*ый — белый, бешеный)\r\n \r\n "+ 
                                                                                "? (знак вопроса) заменит один символ (пример: ию?ь — вернет июль, июнь; пример: ?аска вернет маска, ласка)\r\n \r\n "+
                                                                                "Для ввода дробных чисел использовать символ точки вместо запятой.\r\n \r\n неправильно: 0,4 кВ \r\n правильно: 0.4 кВ \r\n \r\n Все символы (, ? *) можно комбинировать друг с другом."

                                                                            }
                                                                        </div>
                                                                    }>
                                                                    <IconButton size="small">
                                                                        <HelpOutlineIcon fontSize="inherit"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                /> :
                                                <TextField
                                                    id="keywords"
                                                    variant="outlined"
                                                    placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                                    fullWidth
                                                    inputProps={{
                                                        style: {
                                                            paddingRight: 0
                                                        }
                                                    }}
                                                    onChange={(event) => this.props.onKeywordsChanges(event.target.value)}
                                                    value={this.props.keywords.join(',')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment className="input_adornment_keywords"
                                                                            position="start">
                                                                <Tooltip
                                                                    enterTouchDelay={0}
                                                                    title={
                                                                        <div style={{whiteSpace: 'pre-line'}}>
                                                                            {
                                                                                "Чтобы найти все закупки по нескольким ключевым словам, необходимо указать их через пробел или запятую.\r\n "+
                                                                                "Пробел — найдет закупки, которые содержат словосочетание в пределах 3-4 слов. (пример: строительные материалы — результат: материалы и конструкции строительные)\r\n \r\n "+
                                                                                "Запятая — найдет закупки, которые содержат одно из ключевых слов. Работает как «или». (пример: лес, дерево — результат: закупки где содержится ИЛИ лес ИЛИ дерево)\r\n \r\n "+ 
                                                                                "Дополнительно:\r\n * (символ звёздочки) заменит любое количество любых символов\r\n (пример: газ* — результат: газообразный, газель; пример: запрос бе*ый — белый, бешеный)\r\n \r\n "+ 
                                                                                "? (знак вопроса) заменит один символ (пример: ию?ь — вернет июль, июнь; пример: ?аска вернет маска, ласка)\r\n \r\n "+
                                                                                "Для ввода дробных чисел использовать символ точки вместо запятой.\r\n \r\n неправильно: 0,4 кВ \r\n правильно: 0.4 кВ \r\n \r\n Все символы (, ? *) можно комбинировать друг с другом."

                                                                            }
                                                                        </div>
                                                                    }>
                                                                    <IconButton size="small">
                                                                        <HelpOutlineIcon fontSize="inherit"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                        }
                                    </div>

                                    <div className='filter_block filter_block_region'>
                                        <div className='filter_block_header'>
                                            Регион поставки
                                        </div>

                                        <SettlementsSelection kladrCodes={this.props.kladrCodes}
                                                              onKladrCodesChanges={this.props.onKladrCodesChanges}/>
                                    </div>

                                    <div className='filter_block filter_block_region'>
                                        <div className='filter_block_header'>
                                            Заказчик
                                        </div>

                                        <CustomerSelection
                                            customers={this.props.customers}
                                            onCustomersChange={this.props.onCustomersChange}/>
                                    </div>

                                    <div className='filter_block'>
                                        <div className='filter_block_header'>
                                            Начальная цена
                                        </div>
                                        <div className='filter_flex_block'>
                                            <TextField
                                                value={this.props.priceFrom != null ? this.props.priceFrom : ''}
                                                onChange={(event) => {
                                                    const priceFrom = event.target.value;
                                                    this.props.onPriceFromChanges(priceFrom);
                                                }}
                                                name="numberformat"
                                                placeholder="0"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                                variant="outlined"
                                            />
                                            <Box sx={{p: 1, display: 'flex', alignItems: 'center'}}>
                                                –
                                            </Box>
                                            <TextField
                                                value={this.props.priceTo != null ? this.props.priceTo : ''}
                                                onChange={(event) => {
                                                    const priceTo = event.target.value;
                                                    this.props.onPriceToChanges(priceTo);
                                                }}
                                                name="numberformat"
                                                placeholder="0"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                                variant="outlined"
                                            />
                                            <Box sx={{p: 1, display: 'flex', alignItems: 'center'}}>
                                                ₽
                                            </Box>
                                        </div>
                                    </div>
                                </div>

                                <div className="filter_expand_header">
                                    {window.innerWidth < 1290 ? <Switch color="primary" size="small"
                                                                        onClick={() => this.setState(prev => ({
                                                                            ...prev,
                                                                            expandSearch: !prev.expandSearch
                                                                        }))}/> : null}
                                    <div className='filter_block_header'>
                                        Расширенный поиск
                                    </div>
                                </div>

                                {
                                    this.state.expandSearch ?
                                        <div className="filter_expand_container">
                                            <div className='filter_block filter_stopwords'>
                                                <div className='filter_block_header'>
                                                    Исключать слова
                                                </div>
                                                <TextField
                                                    id="keywords"
                                                    variant="outlined"
                                                    placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                                    fullWidth
                                                    multiline
                                                    inputProps={{
                                                        style: {
                                                            padding: 0
                                                        }
                                                    }}
                                                    rows={3}
                                                    onChange={(event) => {
                                                        this.props.onIgnoreKeywordsChanges(event.target.value);
                                                    }}
                                                    value={this.props.ignoreKeywords.join(',')}
                                                />
                                            </div>
                                            <div className='fiter_keywords_buttons'>
                                                <FormControlLabel
                                                    control={<Checkbox/>}
                                                    className='filter_check lonely_check'
                                                    label="Искать в приложенных документах"
                                                    onChange={(event) => this.props.onShouldSearchInDocumentsChanges(event.target.checked)}
                                                    checked={this.props.shouldSearchInDocuments}
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox/>}
                                                    className='filter_check lonely_check'
                                                    label="Искать в наименованиях ОКПД2"
                                                    onChange={(event) => this.props.onShouldSearchInOkpdNames(event.target.checked)}
                                                    checked={this.props.shouldSearchInOkpdNames}
                                                />
                                            </div>

                                            <div className='filter_grid4 filter_grid4_tablet'>
                                                <div className='filter_block date_block'>
                                                    <div className='filter_block_header'>
                                                        Опубликовано
                                                    </div>
                                                    <div className='filter_flex_block'>
                                                        <LocalizationProvider adapterLocale="ru"
                                                                              dateAdapter={AdapterLuxon}>
                                                            <DesktopDatePicker
                                                                value={this.props.datePublicationFrom}
                                                                onChange={(datePublicationFrom) => {
                                                                    this.props.onDatePublicationFromChanges(datePublicationFrom);
                                                                }}
                                                                inputFormat="dd.MM.yyyy"
                                                                mask='__.__.____'
                                                                renderInput={(params) => {
                                                                    return (
                                                                        <TextField
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                placeholder: "От (включ.)"
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                        <Box sx={{p: 1, display: 'flex', alignItems: 'center'}}>
                                                            –
                                                        </Box>
                                                        <LocalizationProvider adapterLocale="ru"
                                                                              dateAdapter={AdapterLuxon}>
                                                            <DesktopDatePicker
                                                                value={this.props.datePublicationTo}
                                                                onChange={(datePublicationTo) => {
                                                                    this.props.onDatePublicationToChanges(datePublicationTo);
                                                                }}
                                                                inputFormat="dd.MM.yyyy"
                                                                mask='__.__.____'
                                                                renderInput={(params) => {
                                                                    return (
                                                                        <TextField
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                placeholder: "До (включ.)"
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>

                                                <div className='filter_block date_block'>
                                                    <div className='filter_block_header'>
                                                        Дата окончания подачи заявок не раньше чем
                                                    </div>
                                                    <div className='filter_flex_block'>
                                                        <LocalizationProvider adapterLocale="ru"
                                                                              dateAdapter={AdapterLuxon}>
                                                            <DesktopDatePicker 
                                                                className='last_date'
                                                                value={this.props.dateExpirationFrom}
                                                                onChange={(dateExpirationFrom) => {
                                                                    this.props.onDateExpirationFromChanges(dateExpirationFrom);
                                                                }}
                                                                inputFormat="dd.MM.yyyy"
                                                                mask='__.__.____'
                                                                renderInput={(params) => {
                                                                    return (
                                                                        <TextField
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                placeholder: "дд.мм.гггг"
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='filter_grid2'>
                                                <div className='filter_block filter_block_etap'>
                                                    <div className='filter_block_header filter_block_header_mobile'
                                                         onClick={() => this.setState(prev => ({
                                                             ...prev,
                                                             expandStage: !prev.expandStage
                                                         }))}>
                                                        Этап
                                                        {this.state.expandStage ?
                                                            <ExpandLess style={{width: "18px", height: "18px"}}/> :
                                                            <ExpandMore style={{width: "18px", height: "18px"}}/>}
                                                    </div>
                                                    {this.state.expandStage ? <div className='filter_check_grid'>
                                                        {
                                                            purchaseStages.map((stage, key) =>
                                                                <FormControlLabel
                                                                    className='filter_check'
                                                                    control={<Checkbox/>}
                                                                    label={stage.name}
                                                                    onChange={this.handleStageChange}
                                                                    value={stage.id}
                                                                    checked={this.props.stages.some(checkedStage => checkedStage === stage.id)}
                                                                    key={key}
                                                                />
                                                            )
                                                        }
                                                    </div> : null}
                                                </div>
                                                <div className='filter_block filter_block_method'>
                                                    <div className='filter_block_header filter_block_header_mobile'
                                                         onClick={() => this.setState(prev => ({
                                                             ...prev,
                                                             expandSourcingMethod: !prev.expandSourcingMethod
                                                         }))}>
                                                        Метод определения поставщика
                                                        {this.state.expandSourcingMethod ?
                                                            <ExpandLess style={{width: "18px", height: "18px"}}/> :
                                                            <ExpandMore style={{width: "18px", height: "18px"}}/>}
                                                    </div>
                                                    {this.state.expandSourcingMethod ?
                                                        <div className='filter_check_grid'>
                                                            {
                                                                auctionTypes.map((auctionType, key) =>
                                                                    <FormControlLabel
                                                                        className='filter_check'
                                                                        control={<Checkbox/>}
                                                                        label={auctionType.name}
                                                                        onChange={this.handleAuctionTypeChange}
                                                                        value={auctionType.id}
                                                                        checked={this.props.auctionTypes.some(checkedAuctionType => checkedAuctionType === auctionType.id)}
                                                                        key={key}
                                                                    />
                                                                )
                                                            }
                                                        </div> : null}
                                                </div>
                                                <div className='filter_block filter_block_type'>
                                                    <div className='filter_block_header filter_block_header_mobile'
                                                         onClick={() => this.setState(prev => ({
                                                             ...prev,
                                                             expandBiddingType: !prev.expandBiddingType
                                                         }))}>
                                                        Тип торгов
                                                        {this.state.expandBiddingType ?
                                                            <ExpandLess style={{width: "18px", height: "18px"}}/> :
                                                            <ExpandMore style={{width: "18px", height: "18px"}}/>}
                                                    </div>
                                                    {this.state.expandBiddingType ? <div className='filter_check_grid'>
                                                        {
                                                            lawTypes.map((law, key) =>
                                                                <FormControlLabel
                                                                    className='filter_check'
                                                                    control={<Checkbox/>}
                                                                    label={law.name}
                                                                    onChange={this.handlePurchaseTypeChange}
                                                                    value={law.id}
                                                                    checked={this.props.laws.some(checkedLaw => checkedLaw === law.id)}
                                                                    key={key}
                                                                />
                                                            )
                                                        }
                                                    </div> : null}
                                                </div>
                                            </div>

                                            <div className='filter_grid3 filter_grid3_mobile'>
                                                <div className='filter_block filter_block_okpd'>
                                                    <div className='filter_block_okpd_inner'>
                                                        <div>
                                                            <Button variant="outlined"
                                                                    onClick={() => this.props.onOpenOkpdSelection(this.props.okpdCodes)}>Выбор
                                                                ОКПД</Button>
                                                        </div>
                                                        {
                                                            this.props.okpdCodes && this.props.okpdCodes.length > 0
                                                                ?
                                                                <div className="filter_block_okpd_selected">
                                                                    Выбрано: {this.props.okpdCodes.length}
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    {/* <div>
                        <div>
                          <Button variant="outlined" onClick={() => this.props.onOpenIndustrySelection(this.props.industryCodes)}>Выбор отраслей</Button>
                        </div>
                        {
                          this.props.industryCodes && this.props.industryCodes.length > 0
                          ?
                          <div className="filter_block_okpd_selected">
                            Выбрано: {this.props.industryCodes.length}
                          </div>
                          :
                          null
                        }
                      </div> */}
                                                </div>
                                                <div className='filter_block_inner_container'>
                                                    <Tooltip title="Искать закупки в которых не указана цена контракта">
                                                        <FormControlLabel
                                                            control={<Checkbox/>}
                                                            className='filter_check lonely_check'
                                                            label="Включая закупки без НМЦ"
                                                            onChange={(event) => this.props.onShouldIncludeEmptyPriceChanges(event.target.checked)}
                                                            checked={this.props.shouldIncludeEmptyPrice}
                                                        />
                                                    </Tooltip>

                                                    <FormControlLabel
                                                        control={<Checkbox/>}
                                                        className='filter_check lonely_check'
                                                        label="Наличие аванса"
                                                        onChange={(event) => this.props.onHavePrepaymentChanges(event.target.checked)}
                                                        checked={this.props.havePrepayment}
                                                    />

                                                    <Tooltip title="Прогнозируемое снижение менее 5%">
                                                        <FormControlLabel
                                                            control={<Checkbox/>}
                                                            className='filter_check lonely_check'
                                                            label='Рекомендация "Эксперт Центр"'
                                                            onChange={(event) => this.props.onHaveRecommendationChanges(event.target.checked)}
                                                            checked={this.props.haveRecommendation}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div> : null
                                }

                                <div className='filter_buttons'>
                                    <ButtonWithWaitForCompletion
                                        onClick={() => this.props.onSearchPurchases(this.props)}
                                        variant="contained"
                                        type="submit"
                                        disabled={this.props.isLoaded}
                                    >
                                        Найти
                                    </ButtonWithWaitForCompletion>
                                    {this.props.templateId ?
                                        <>
                                            <Button
                                                onClick={() => this.props.onSaveTemplate(this.props)}
                                                variant="contained"
                                                sx={{ml: 2}}
                                                disabled={!this.props.canSaveTemplate}
                                                color="success"
                                            >
                                                Сохранить изменения
                                            </Button>

                                            <ConfirmationDialog
                                                onConfirm={() => {
                                                    return this.props.onRemoveTemplate(this.props);
                                                }}
                                                confirmationText="Вы действительно хотите удалить этот шаблон?"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    sx={{ml: 2}}
                                                    color="error"
                                                >
                                                    Удалить шаблон
                                                </Button>
                                            </ConfirmationDialog>
                                        </>
                                        :
                                        <Button
                                            className="filter_button_save"
                                            onClick={this.props.onOpenSaveTemplateModal}
                                            variant="outlined"
                                            sx={{ml: 2}}
                                        >
                                            Сохранить как шаблон
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => this.props.onResetSearch(this.props.currentPage)}
                                        variant="outlined"
                                        sx={{float: 'right'}}
                                    >
                                        Сбросить поиск
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <PurchaseSearchResult onPageSearch={this.handlePageSearch}/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                        }
                        }>
                            <div className="filter_grid">

                                {this.props.templateId ?
                                    <div className='tempalte_inputs'>
                                        <div className='filter_block filter_template_name'>
                                            <div className='filter_block_header'>
                                                Наименование шаблона
                                            </div>
                                            <TextField
                                                id="keywords"
                                                variant="outlined"
                                                fullWidth
                                                onChange={(event) => this.props.onTemplateNameChanges(event.target.value)}
                                                value={this.props.name}
                                            />
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className='filter_grid1'>
                                    <div className='filter_block filter_keywords'>
                                        <div className='filter_block_header'>
                                            Ключевые слова
                                        </div>
                                        <TextField
                                            id="keywords"
                                            variant="outlined"
                                            placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                            fullWidth
                                            multiline
                                            inputProps={{
                                                style: {
                                                    padding: 0
                                                }
                                            }}
                                            rows={3}
                                            onChange={(event) => this.props.onKeywordsChanges(event.target.value)}
                                            value={this.props.keywords.join(',')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <Tooltip
                                                            title={
                                                                <div style={{whiteSpace: 'pre-line'}}>
                                                                    {
                                                                        " - если введены несколько слов через пробел \r\n"
                                                                        + " ищутся закупки содержащие их все; \r\n"
                                                                        + " - альтернативные варианты вводятся через запятую; \r\n"
                                                                        + " - для поиска по маске используются знаки * и ?; \r\n"
                                                                        + " - * заменяет любое количество любых символов; \r\n"
                                                                        + " пример: \r\n"
                                                                        + " запрос \"газ*\" вернет \"газообразный\", \"газель\" \r\n"
                                                                        + " запрос \"бе*ый\" вернет \"белый\", \"бешеный\" \r\n"
                                                                        + " не рекомендуется использовать * в начале слова \r\n"
                                                                        + " - ? заменяет один любой символ \r\n"
                                                                        + " пример: \r\n"
                                                                        + " запрос \"?аска\" вернет \"маска\", \"ласка\" \r\n"
                                                                        + " запрос \"ию?ь\" вернет \"июль\", \"июнь\" \r\n"
                                                                    }
                                                                </div>
                                                            }>
                                                            <IconButton size="small">
                                                                <HelpOutlineIcon fontSize="inherit"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className='filter_block filter_stopwords'>
                                        <div className='filter_block_header'>
                                            Исключать слова
                                        </div>
                                        <TextField
                                            id="keywords"
                                            variant="outlined"
                                            placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                            fullWidth
                                            multiline
                                            inputProps={{
                                                style: {
                                                    padding: 0
                                                }
                                            }}
                                            rows={3}
                                            onChange={(event) => {
                                                this.props.onIgnoreKeywordsChanges(event.target.value);
                                            }}
                                            value={this.props.ignoreKeywords.join(',')}
                                        />
                                    </div>
                                    <div className='fiter_keywords_buttons'>
                                        <FormControlLabel
                                            control={<Checkbox/>}
                                            className='filter_check lonely_check'
                                            label="Искать в приложенных документах"
                                            onChange={(event) => this.props.onShouldSearchInDocumentsChanges(event.target.checked)}
                                            checked={this.props.shouldSearchInDocuments}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox/>}
                                            className='filter_check lonely_check'
                                            label="Искать в наименованиях ОКПД2"
                                            onChange={(event) => this.props.onShouldSearchInOkpdNames(event.target.checked)}
                                            checked={this.props.shouldSearchInOkpdNames}
                                        />
                                    </div>
                                </div>

                                <div className='filter_block filter_block_region'>
                                    <div className='filter_block_header'>
                                        Регион поставки
                                    </div>

                                    <SettlementsSelection kladrCodes={this.props.kladrCodes}
                                                          onKladrCodesChanges={this.props.onKladrCodesChanges}/>
                                </div>

                                <div className='filter_block'>
                                    <div className='filter_block_header'>
                                        Заказчик
                                    </div>

                                    <CustomerSelection
                                        customers={this.props.customers}
                                        onCustomersChange={this.props.onCustomersChange}/>
                                </div>

                                <div className='filter_grid4'>
                                    <div className='filter_block'>
                                        <div className='filter_block_header'>
                                            Начальная цена
                                        </div>
                                        <div className='filter_flex_block'>
                                            <TextField
                                                value={this.props.priceFrom != null ? this.props.priceFrom : ''}
                                                onChange={(event) => {
                                                    const priceFrom = event.target.value;
                                                    this.props.onPriceFromChanges(priceFrom);
                                                }}
                                                name="numberformat"
                                                placeholder="0"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                                variant="outlined"
                                            />
                                            <Box sx={{p: 1, display: 'flex', alignItems: 'center'}}>
                                                –
                                            </Box>
                                            <TextField
                                                value={this.props.priceTo != null ? this.props.priceTo : ''}
                                                onChange={(event) => {
                                                    const priceTo = event.target.value;
                                                    this.props.onPriceToChanges(priceTo);
                                                }}
                                                name="numberformat"
                                                placeholder="0"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                                variant="outlined"
                                            />
                                            <Box sx={{p: 1, display: 'flex', alignItems: 'center'}}>
                                                ₽
                                            </Box>
                                        </div>
                                    </div>

                                    <div className='filter_block date_block'>
                                        <div className='filter_block_header'>
                                            Опубликовано
                                        </div>
                                        <div className='filter_flex_block'>
                                            <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterLuxon}>
                                                <DesktopDatePicker
                                                    value={this.props.datePublicationFrom}
                                                    onChange={(datePublicationFrom) => {
                                                        this.props.onDatePublicationFromChanges(datePublicationFrom);
                                                    }}
                                                    inputFormat="dd.MM.yyyy"
                                                    mask='__.__.____'
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: "От (включ.)"
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <Box sx={{p: 1, display: 'flex', alignItems: 'center'}}>
                                                –
                                            </Box>
                                            <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterLuxon}>
                                                <DesktopDatePicker
                                                    value={this.props.datePublicationTo}
                                                    onChange={(datePublicationTo) => this.props.onDatePublicationToChanges(datePublicationTo)}
                                                    inputFormat="dd.MM.yyyy"
                                                    mask='__.__.____'
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: "До (включ.)"
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className='filter_block date_block'>
                                        <div className='filter_block_header'>
                                            Дата окончания подачи заявок не раньше чем
                                        </div>
                                        <div className='filter_flex_block'>
                                            <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterLuxon}>
                                                <DesktopDatePicker
                                                    className='last_date'
                                                    value={this.props.dateExpirationFrom}
                                                    onChange={(dateExpirationFrom) =>  this.props.onDateExpirationFromChanges(dateExpirationFrom)}
                                                    inputFormat="dd.MM.yyyy"
                                                    mask='__.__.____'
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: "дд.мм.гггг"
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter_grid2'>
                                    <div className='filter_block filter_block_etap'>
                                        <div className='filter_block_header'>
                                            Этап
                                        </div>
                                        <div className='filter_check_grid'>
                                            {
                                                purchaseStages.map((stage, key) =>
                                                    <FormControlLabel
                                                        className='filter_check'
                                                        control={<Checkbox/>}
                                                        label={stage.name}
                                                        onChange={this.handleStageChange}
                                                        value={stage.id}
                                                        checked={this.props.stages.some(checkedStage => checkedStage === stage.id)}
                                                        key={key}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='filter_block filter_block_method'>
                                        <div className='filter_block_header'>
                                            Метод определения поставщика
                                        </div>
                                        <div className='filter_check_grid'>
                                            {
                                                auctionTypes.map((auctionType, key) =>
                                                    <FormControlLabel
                                                        className='filter_check'
                                                        control={<Checkbox/>}
                                                        label={auctionType.name}
                                                        onChange={this.handleAuctionTypeChange}
                                                        value={auctionType.id}
                                                        checked={this.props.auctionTypes.some(checkedAuctionType => checkedAuctionType === auctionType.id)}
                                                        key={key}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='filter_block filter_block_type'>
                                        <div className='filter_block_header'>
                                            Тип торгов
                                        </div>
                                        <div className='filter_check_grid'>
                                            {
                                                lawTypes.map((law, key) =>
                                                    <FormControlLabel
                                                        className='filter_check'
                                                        control={<Checkbox/>}
                                                        label={law.name}
                                                        onChange={this.handlePurchaseTypeChange}
                                                        value={law.id}
                                                        checked={this.props.laws.some(checkedLaw => checkedLaw === law.id)}
                                                        key={key}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='filter_grid3'>
                                    <div className='filter_block filter_block_okpd'>
                                        <div>
                                            <div>
                                                <Button variant="outlined"
                                                        onClick={() => this.props.onOpenOkpdSelection(this.props.okpdCodes)}>Выбор
                                                    ОКПД</Button>
                                            </div>
                                            {
                                                this.props.okpdCodes && this.props.okpdCodes.length > 0
                                                    ?
                                                    <div className="filter_block_okpd_selected">
                                                        Выбрано: {this.props.okpdCodes.length}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {/* <div>
                        <div>
                          <Button variant="outlined" onClick={() => this.props.onOpenIndustrySelection(this.props.industryCodes)}>Выбор отраслей</Button>
                        </div>
                        {
                          this.props.industryCodes && this.props.industryCodes.length > 0
                          ?
                          <div className="filter_block_okpd_selected">
                            Выбрано: {this.props.industryCodes.length}
                          </div>
                          :
                          null
                        }
                      </div> */}
                                    </div>
                                    <Tooltip title="Искать закупки в которых не указана цена контракта">
                                        <FormControlLabel
                                            control={<Checkbox/>}
                                            className='filter_check lonely_check'
                                            label="Включая закупки без НМЦ"
                                            onChange={(event) => this.props.onShouldIncludeEmptyPriceChanges(event.target.checked)}
                                            checked={this.props.shouldIncludeEmptyPrice}
                                        />
                                    </Tooltip>

                                    <FormControlLabel
                                        control={<Checkbox/>}
                                        className='filter_check lonely_check'
                                        label="Наличие аванса"
                                        onChange={(event) => this.props.onHavePrepaymentChanges(event.target.checked)}
                                        checked={this.props.havePrepayment}
                                    />

                                    <Tooltip title="Прогнозируемое снижение менее 5%">
                                        <FormControlLabel
                                            control={<Checkbox/>}
                                            className='filter_check lonely_check'
                                            label='Рекомендация "Эксперт Центр"'
                                            onChange={(event) => this.props.onHaveRecommendationChanges(event.target.checked)}
                                            checked={this.props.haveRecommendation}
                                        />
                                    </Tooltip>
                                </div>

                                <div className='filter_buttons'>
                                    <Button
                                        onClick={() => this.props.onSearchPurchases(this.props)}
                                        variant="contained"
                                        type="submit"
                                        disabled={this.props.isLoaded}
                                    >
                                        Найти
                                    </Button>
                                    {this.props.templateId ?
                                        <>
                                            <Button
                                                onClick={() => this.props.onSaveTemplate(this.props)}
                                                variant="contained"
                                                sx={{ml: 2}}
                                                disabled={!this.props.canSaveTemplate}
                                                color="success"
                                            >
                                                Сохранить изменения
                                            </Button>

                                            <ConfirmationDialog
                                                onConfirm={() => {
                                                    return this.props.onRemoveTemplate(this.props);
                                                }}
                                                confirmationText="Вы действительно хотите удалить этот шаблон?"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    sx={{ml: 2}}
                                                    color="error"
                                                >
                                                    Удалить шаблон
                                                </Button>
                                            </ConfirmationDialog>
                                        </>
                                        :
                                        <Button
                                            onClick={this.props.onOpenSaveTemplateModal}
                                            variant="outlined"
                                            sx={{ml: 2}}
                                        >
                                            Сохранить как шаблон
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => this.props.onResetSearch(this.props.currentPage)}
                                        variant="outlined"
                                        sx={{float: 'right'}}
                                    >
                                        Сбросить поиск
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <PurchaseSearchResult onPageSearch={this.handlePageSearch}/>
                    </React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        keywords: state.searchPurchasesState.keywords,
        ignoreKeywords: state.searchPurchasesState.ignoreKeywords,
        shouldSearchInDocuments: state.searchPurchasesState.shouldSearchInDocuments,
        shouldSearchInOkpdNames: state.searchPurchasesState.shouldSearchInOkpdNames,
        havePrepayment: state.searchPurchasesState.havePrepayment,
        haveRecommendation: state.searchPurchasesState.haveRecommendation,
        laws: state.searchPurchasesState.laws,
        datePublicationFrom: state.searchPurchasesState.datePublicationFrom,
        datePublicationTo: state.searchPurchasesState.datePublicationTo,
        dateExpirationFrom: state.searchPurchasesState.dateExpirationFrom,
        settlements: state.searchPurchasesState.settlements,
        priceFrom: state.searchPurchasesState.priceFrom,
        priceTo: state.searchPurchasesState.priceTo,
        stages: state.searchPurchasesState.stages,
        kladrCodes: state.searchPurchasesState.kladrCodes,
        customers: state.searchPurchasesState.customers,
        auctionTypes: state.searchPurchasesState.auctionTypes,
        okpdCodes: state.searchPurchasesState.okpdCodes,
        industryCodes: state.searchPurchasesState.industryCodes,
        shouldIncludeEmptyPrice: state.searchPurchasesState.shouldIncludeEmptyPrice,

        isLoaded: state.searchPurchasesState.isLoaded,
        sortBy: state.searchPurchasesState.sortBy,
        ascending: state.searchPurchasesState.ascending,

        templateId: state.searchPurchasesState.templateId,
        templates: state.searchPurchasesState.templates,
        canSaveTemplate: state.searchPurchasesState.canSaveTemplate,
        purchases: state.searchPurchasesState.purchases,
        name: state.searchPurchasesState.name,
        isSuccessNotificationShow: state.searchPurchasesState.isSuccessNotificationShow,
        notificationMessage: state.searchPurchasesState.notificationMessage,
        notificationKey: state.searchPurchasesState.notificationKey,
        notifyDays: state.searchPurchasesState.notifyDays,
        notifyIsEnabled: state.searchPurchasesState.notifyIsEnabled,

        settlementsLoading: state.searchPurchasesState.settlementsLoading,

        selectedPurchase: state.searchPurchasesState.selectedPurchase,
        openTagDialog: state.searchPurchasesState.openTagDialog,

        pageNumber: state.searchPurchasesState.pageNumber,

        currentCompany: state.profileState.currentCompany,

        scrollPosition: state.searchPurchasesState.scrollPosition
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onPageOpen: () => {
            const action = {
                type: 'CURRENT_PAGE_CHANGED',
                currentPage: 'search'
            };
            dispatch(action);
        },
        onResetSearch: (currentPage) => {
            const action = {
                type: 'RESET_SEARCH_PURCHASES',
                currentPage: currentPage
            };
            dispatch(action);
        },
        onSearchPurchases: async (state, pageNumber) => {
            const action = {
                type: 'SEARCH_PURCHASES'
            };
            dispatch(action);

            let okpdCodes = [];
            state.okpdCodes.forEach(async element => {
                if (element.key.length === 1) {
                    //need rework - in search okpd children objects like { key = "F", children: [{ key = "child"}] }
                    //from templates comes just array of strings { key = "F", children ["child"]}
                    const childKeys = element.children.map(opt => opt.key ? opt.key : opt);
                    childKeys.forEach(child => {
                        okpdCodes = okpdCodes.concat(child);
                    });
                } else {
                    okpdCodes = okpdCodes.concat(element.key);
                }
            });

            return PurchaseService.search({
                keywords: state.keywords,
                ignoreKeywords: state.ignoreKeywords,
                shouldSearchInDocuments: state.shouldSearchInDocuments,
                shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                havePrepayment: state.havePrepayment,
                haveRecommendation: state.haveRecommendation,
                laws: state.laws,
                datePublicationFrom: state.datePublicationFrom,
                datePublicationTo: state.datePublicationTo,
                dateExpirationFrom: state.dateExpirationFrom,
                priceFrom: parseInt(state.priceFrom),
                priceTo: parseInt(state.priceTo),
                stages: state.stages,
                kladrCodes: state.kladrCodes.map(opt => opt.code),
                auctionTypes: state.auctionTypes,
                okpdCodes: okpdCodes,
                industryCodes: state.industryCodes.map(opt => opt.key),
                customerIds: state.customers.map(opt => opt.id),
                shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
                pageNumber: pageNumber,
                sortBy: state.sortBy,
                asc: state.ascending
            })
                .then(result => {
                    var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

                    CustomerRatingService.getCustomerRatings(customerOrgIds)
                        .then(result => {
                            if (result) {
                                const action = {
                                    type: 'CUSTOMER_RATINGS_FOUND',
                                    customerRatings: result
                                };
                                dispatch(action);
                            }
                        })
                        .catch((error) => {
                            state.enqueueSnackbar(
                                'Произошла ошибка при получении рейтинга заказчиков',
                                {
                                    variant: 'error'
                                });
                        });

                    var purchaseIds = result.collections.map(c => c.id);
                    PurchaseTagsService.getPurchaseTags(purchaseIds)
                        .then(result => {
                            const action = {
                                type: 'PURCHASE_TAGS_FOUND',
                                purchaseTags: result
                            };
                            dispatch(action);
                        })
                        .catch((error) => {
                            state.enqueueSnackbar(
                                'Произошла ошибка при получении избранного',
                                {
                                    variant: 'error'
                                });
                        });

                    //если у пользователя нет привязанной компании не делаем запрос закупок в работе
                    if (state.currentCompany && state.currentCompany.Company) {
                        purchasesInWorkService.checkIfPurchasesInWork(state.currentCompany.Id, result.collections)
                            .then(result => {
                                const action = {
                                    type: 'PURCHASE_IN_WORK_FOUND',
                                    purchasesInWork: result
                                };
                                dispatch(action);
                            })
                            .catch((error) => {
                                state.enqueueSnackbar(
                                    'Произошла ошибка при получении закупок в работе',
                                    {
                                        variant: 'error'
                                    });
                            });
                    }

                    const action = {
                        type: 'PURCHASES_FOUND',
                        purchases: result.collections,
                        pageNumber: result.pageNumber,
                        pageSize: result.pageSize,
                        total: result.total,
                        pageCount: result.pageCount,
                        keywords: state.keywords,
                        ignoreKeywords: state.ignoreKeywords,
                        shouldSearchInDocuments: state.shouldSearchInDocuments,
                        shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                        havePrepayment: state.havePrepayment,
                        haveRecommendation: state.haveRecommendation,
                        laws: state.laws,
                        datePublicationFrom: state.datePublicationFrom,
                        datePublicationTo: state.datePublicationTo,
                        dateExpirationFrom: state.dateExpirationFrom,
                        priceFrom: state.priceFrom,
                        priceTo: state.priceTo,
                        stages: state.stages,
                        auctionTypes: state.auctionTypes,
                        kladrCodes: state.kladrCodes,
                        customers: state.customers,
                        okpdCodes: state.okpdCodes,
                        industryCodes: state.industryCodes,
                        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice
                    };
                    dispatch(action);
                })
                .catch((error) => {
                    const action = {
                        type: 'SEARCH_PURCHASES_FAILED'
                    };
                    dispatch(action);
                    state.enqueueSnackbar(
                        'Произошла ошибка при попытке поиска',
                        {
                            variant: 'error'
                        });
                })
        },
        onSearchSettlements: (keyword, enqueueSnackbar) => {
            const action = {
                type: 'SETTLEMENTS_SEARCH'
            };
            dispatch(action);
            SettlementService.search(keyword)
                .then(settlements => {
                    const action = {
                        type: 'SETTLEMENTS_FOUND',
                        settlements: settlements
                    };
                    dispatch(action);
                })
                .catch((error) => {
                    const action = {
                        type: 'SETTLEMENTS_SEARCH_FAILED'
                    };
                    dispatch(action);
                    enqueueSnackbar(
                        'Произошла ошибка при получении списка населенных пунктов',
                        {
                            variant: 'error'
                        });
                });
        },
        onClearSettlements: (keyword) => {
            const action = {
                type: 'SETTLEMENTS_RESET'
            };
            dispatch(action);
        },
        onKeywordsChanges: (keywords) => {
            const action = {
                type: 'KEYWORDS_CHANGES',
                keywords: keywords
            }
            dispatch(action);
        },
        onIgnoreKeywordsChanges: (ignoreKeywords) => {
            const action = {
                type: 'IGNORE_KEYWORDS_CHANGES',
                ignoreKeywords: ignoreKeywords
            }
            dispatch(action);
        },
        onShouldSearchInDocumentsChanges: (value) => {
            const action = {
                type: 'SHOULD_SEARCH_IN_DOCUMENTS_CHANGES',
                shouldSearchInDocuments: value
            }
            dispatch(action);
        },
        onShouldSearchInOkpdNames: (value) => {
            const action = {
                type: 'SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES',
                shouldSearchInOkpdNames: value
            }
            dispatch(action);
        },
        onShouldIncludeEmptyPriceChanges: (value) => {
            const action = {
                type: 'SHOULD_INCLUDE_EMPTY_PRICE_CHANGES',
                shouldIncludeEmptyPrice: value
            }
            dispatch(action);
        },
        onKladrCodesChanges: (kladrCodes) => {
            const action = {
                type: 'KLADR_CODES_CHANGES',
                kladrCodes: kladrCodes
            }
            dispatch(action);
        },
        onCustomersChange: (customers) => {
            const action = {
                type: 'CUSTOMER_CHANGES',
                customers: customers
            }
            dispatch(action)
        },
        onAddStage: (stageId) => {
            const action = {
                type: 'ADD_STAGE',
                stageId: stageId
            }
            dispatch(action);
        },
        onRemoveStage: (stageId) => {
            const action = {
                type: 'REMOVE_STAGE',
                stageId: stageId
            }
            dispatch(action);
        },
        onAddAuctionType: (auctionTypeId) => {
            const action = {
                type: 'ADD_AUCTION_TYPE',
                auctionTypeId: auctionTypeId
            }
            dispatch(action);
        },
        onRemoveAuctionType: (auctionTypeId) => {
            const action = {
                type: 'REMOVE_AUCTION_TYPE',
                auctionTypeId: auctionTypeId
            }
            dispatch(action);
        },
        onPriceFromChanges: (value) => {
            const action = {
                type: 'PRICE_FROM_CHANGES',
                priceFrom: value
            }
            dispatch(action);
        },
        onPriceToChanges: (value) => {
            const action = {
                type: 'PRICE_TO_CHANGES',
                priceTo: value
            }
            dispatch(action);
        },
        onHavePrepaymentChanges: (value) => {
            const action = {
                type: 'HAVE_PREPAYMENT_CHANGES',
                havePrepayment: value
            }
            dispatch(action);
        },
        onHaveRecommendationChanges: (value) => {
            const action = {
                type: 'HAVE_RECOMMENDATION_CHANGES',
                haveRecommendation: value
            }
            dispatch(action);
        },
        onDatePublicationFromChanges: (value) => {
            const action = {
                type: 'DATE_PUBLICATION_FROM_CHANGES',
                datePublicationFrom: value
            }
            dispatch(action);
        },
        onDatePublicationToChanges: (value) => {
            const action = {
                type: 'DATE_PUBLICATION_TO_CHANGES',
                datePublicationTo: value
            }
            dispatch(action);
        },
        onDateExpirationFromChanges: (value) => {
            const action = {
                type: 'DATE_EXPIRATION_FROM_CHANGES',
                dateExpirationFrom: value
            };
            dispatch(action);
        },
        onAddLawChanges: (value) => {
            const action = {
                type: 'ADD_LAW_CHANGES',
                lawId: value
            }
            dispatch(action);
        },
        onRemoveLawChanges: (value) => {
            const action = {
                type: 'REMOVE_LAW_CHANGES',
                lawId: value
            }
            dispatch(action);
        },
        onRemoveTemplate: async (state) => {
            return TemplateService.remove(state.templateId)
                .then(result => {
                    const action = {
                        type: 'RESET_TEMPLATES'
                    };
                    dispatch(action);

                    state.enqueueSnackbar(
                        'Шаблон успешно удален',
                        {
                            variant: 'success'
                        });

                    TemplateService.getAll()
                        .then(templates => {
                            const action = {
                                type: 'GET_TEMPLATES',
                                templates: templates
                            };

                            dispatch(action);
                        })
                        .catch((error) => {
                            state.enqueueSnackbar(
                                'Произошла ошибка при получении списка шаблонов',
                                {
                                    variant: 'error'
                                });
                        });
                })
                .catch((error) => {
                    state.enqueueSnackbar(
                        'Произошла ошибка при удалении шаблона',
                        {
                            variant: 'error'
                        });
                });
        },
        onSaveTemplate: (state) => {
            TemplateService.update(state.templateId, {
                name: state.name,
                keywords: state.keywords,
                ignoreKeywords: state.ignoreKeywords,
                shouldSearchInDocuments: state.shouldSearchInDocuments,
                shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                havePrepayment: state.havePrepayment,
                haveRecommendation: state.haveRecommendation,
                laws: state.laws,
                datePublicationFrom: state.datePublicationFrom,
                datePublicationTo: state.datePublicationTo,
                dateExpirationFrom: state.dateExpirationFrom,
                priceFrom: state.priceFrom,
                priceTo: state.priceTo,
                stages: state.stages,
                kladrCodes: state.kladrCodes,
                auctionTypes: state.auctionTypes,
                okpdCodes: state.okpdCodes.map((code) => {
                    return {key: code.key, children: code.children.flatMap((child) => child.key ?? child)}
                }),
                industryCodes: state.industryCodes,
                customers: state.customers,
                shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,

                emailNotificationOptions: {
                    notifyDays: state.notifyDays,
                    isEnabled: state.notifyIsEnabled
                }
            })
                .then((template) => {
                    state.enqueueSnackbar(
                        'Шаблон успешно сохранен',
                        {
                            variant: 'success'
                        });

                    const action = {
                        type: 'TEMPLATE_EXISTING_SAVED',
                        template: template
                    }
                    dispatch(action);
                })
                .catch((error) => {
                    state.enqueueSnackbar(
                        'Произошла ошибка при сохранении шаблона',
                        {
                            variant: 'error'
                        });
                });
        },
        onTemplateNameChanges: (templateName) => {
            const action = {
                type: 'TEMPLATE_NAME_CHANGES',
                templateName: templateName
            }
            dispatch(action);
        },
        onAddDayNotify: (value) => {
            const action = {
                type: 'ADD_DAY_NOTIFY',
                notifyDay: parseInt(value)
            }
            dispatch(action);
        },
        onRemoveDayNotify: (value) => {
            const action = {
                type: 'REMOVE_DAY_NOTIFY',
                notifyDay: parseInt(value)
            }
            dispatch(action);
        },
        onEmailNotifyEnable: () => {
            const action = {
                type: 'EMAIL_NOTIFY_ENABLE'
            }
            dispatch(action);
        },
        onEmailNotifyDisable: () => {
            const action = {
                type: 'EMAIL_NOTIFY_DISABLE'
            }
            dispatch(action);
        },
        onCloseTagDialog: (purchaseId) => {
            const purchaseIds = [purchaseId];
            PurchaseTagsService.getPurchaseTags(purchaseIds)
                .then(result => {
                    const action = {
                        type: 'REFRESH_PURCHASE_TAGS',
                        purchase: result
                    };
                    dispatch(action);
                });
        },
        onOpenTagDialog: (selectedPurchase) => {
            const action = {
                type: 'OPEN_TAG_DIALOG',
                selectedPurchase: selectedPurchase
            };
            dispatch(action);
        },
        onOpenOkpdSelection: (checkedOkpdCodes) => {
            const action = {
                type: 'OKPD_SELECTION_OPENED',
                checkedOkpdCodes: checkedOkpdCodes
            };
            dispatch(action);
        },
        onOpenIndustrySelection: (checkedIndustryCodes) => {
            const action = {
                type: 'INDUSTRIES_SELECTION_OPENED',
                checkedIndustryCodes: checkedIndustryCodes
            };
            dispatch(action);
        },
        onOpenSaveTemplateModal: () => {
            const action = {
                type: 'OPEN_SAVE_TEMPLATE_DIALOG',
            };
            dispatch(action);
        }
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(PurchaseSearchPanel));


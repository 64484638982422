import React, { useState } from "react"
import { Modal, Button, Box, Typography, TextField, FormControlLabel, Checkbox, Autocomplete, FormHelperText } from '@mui/material';

import companiesService from "../../services/companiesService";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export const RemoveCompanyModal = (props) => {
    const [comment, setComment] = useState();

    const handleRemoveCompany = () => {
        const submitValue = {
            AcvId: props.company.Id,
            Comment: comment
        }
        props.onSubmit(submitValue);
    }

    return (
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={props.onClose}
            >
                <Box className="modal_body">
                    <h2 className="modal_header"> 
                        Вы собираетесь удалить привязку к компании <span>{props.company.Name} | ИНН {props.company.TaxPayerNumber}</span>
                    </h2>

                    <Typography  sx={{mt: 2}}>
                        Комментарий:
                    </Typography>
                    <TextField
                        multiline
                        rows={4}
                        title="Комментарий"
                        fullWidth
                        onChange={(event) => {
                            setComment(event.target.value);
                        }}
                        sx={{mb: 2}}
                    >

                    </TextField>
                    <Button 
                        variant="contained"
                        type="submit"
                        onClick={handleRemoveCompany}
                        sx={{mr: 2}}
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={props.onClose}
                    >
                        Отмена
                    </Button>
                </Box>
            </Modal>
        </React.Fragment>
    )
}
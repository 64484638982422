const initialState = {
  openSaveTemplateDialog: false,
}

const reducer = (state = initialState, action) => {
  if (action.type === 'OPEN_SAVE_TEMPLATE_DIALOG') {
    return {
      ...state,
      openSaveTemplateDialog: true
    }
  }

  if (action.type === 'CLOSE_SAVE_TEMPLATE_DIALOG') {
    return {
      ...state,
      openSaveTemplateDialog: false
    }
  }
  
  return state;
}

export default reducer;
import { connect, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

export const isCompanyHasContract = (props) => {
  if (props.currentCompany.Company){
    return props.currentCompany.Company.ManagerInChargeId;
  }
  return null;
}

const isCompanySelected = (props) => {
  return props.currentCompany;
}

// returns component if have contract;
// when no contract return empty component or component with added withoutAccess prop
export const CheckIsCompanyWithContractWrapper = (Component, hideComponentWhenNoContract) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    if (isCompanySelected(props) && isCompanyHasContract(props)){
      return <Component navigate={navigate} {...props} />;
    }
    else {
      if (hideComponentWhenNoContract === true){
        return <></>;
      }
      else {
        return <Component withoutAccess={true} {...props } />
      }
    }
  };

  function mapStateToProps(state) {
    return {
        currentCompany: state.profileState.currentCompany
    }
  }

  return connect(mapStateToProps)(Wrapper);
}



import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Dialog, TextField, Typography, Grid, Box, CircularProgress, FormHelperText, Stack, Chip } from '@mui/material';
import Tree from 'rc-tree';
import '../../css/rc-tree.css';
import OkpdService from '../../services/search-purchases-services/OkpdService'
import { withSnackbar } from 'notistack';

export function OkpdSelection(props) {
  const [searchInputTimeout, setSearchInputTimeout] = useState();

  useEffect(() => {
    props.onInitialLoad(props.checkedOkpdCodes, props.enqueueSnackbar);
  }, [props.openOkpdSelectionModal])

  return (
    <React.Fragment>
      <Dialog 
        open={props.openOkpdSelectionModal} 
        onClose={props.onCloseOkpdSelection}
        maxWidth="80%"
        PaperProps={{sx: {
          width: "1300px",
          //height: "620px",
          display: 'flex',
          flexDirection:'column',
          justifyContent: 'space-between',
          gap:'20px', 
          paddingTop:'10px',
        }}}
      >
        <Card sx={{overflowY: "auto", height: "550px"}}>
          <Grid container spacing="0" alignItems="center" sx={{mb:2}}>
            <Grid item sx={{mr:1, ml:1}}>
                Поиск по ОКПД:
            </Grid>
            <Grid item>
              <TextField
                variant = "standard"
                size = "small"
                onChange={(event) => {
                    const value = event.target.value;
                    if (value) {
                      if (searchInputTimeout !== null){
                        clearTimeout(searchInputTimeout);
                      }
                      setSearchInputTimeout(setTimeout(() => {
                        if (value.replace(' ','').length > 0) {
                          props.onInputOkpdSearch(value, props.enqueueSnackbar);
                        }
                      }, 500));
                    }
                    else {
                      clearTimeout(searchInputTimeout);
                      props.onInitialLoad(props.checkedOkpdCodes, props.enqueueSnackbar)
                    }
                  }
                }
              />
            </Grid>
            <Grid item sx={{ml:2}}>
              <FormHelperText error>
                {props.searchWarningMessage}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid sx={{overflowY: "scroll", maxHeight:'510px'}}>
          {props.isLoading 
            ?
              <CircularProgress/>
            :
              <Tree
                loadData={(node) => props.onChildNodeExpand(node, props.checkedOkpdCodes, props.enqueueSnackbar)}
                checkable
                treeData={props.OkpdCodes}
                checkedKeys={props.checkedOkpdCodes.map(code => { return code.key; })}
                onCheck={props.onSetCheckedOkpdCodes}
                expandedKeys={props.expandedOkpdCodes}
                onExpand={props.onOkpdExpanded}
                showIcon={false}
                className="highlighted"
              />
          }
          </Grid>
        </Card>
        <Stack marginTop={'10px'} direction="row" /* spacing={1}*/ flexWrap="wrap" sx={{overflowY: "auto", maxHeight: "200px"}}>
          { props.checkedOkpdCodes.map((okpd, key) =>
              <Chip sx={{ ml: 1, mt: 1 }} label={okpd.key} key={key} onDelete={() => props.onRemoveSingleOkpdSelection(okpd.key)}/>
          ) }
        </Stack>
        <Button sx={{marginBottom:'0px'}} onClick={() => props.onOkpdSelected(props.checkedOkpdCodes, props.currentPage)}>Выбрать</Button>
      </Dialog>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    OkpdCodes: state.okpdState.OkpdCodes,
    checkedOkpdCodes: state.okpdState.checkedOkpdCodes,
    openOkpdSelectionModal: state.okpdState.openOkpdSelectionModal,
    expandedOkpdCodes: state.okpdState.expandedOkpdCodes,
    isLoading: state.okpdState.isLoading,
    searchWarningMessage: state.okpdState.searchWarningMessage,
    currentPage: state.pagesState.currentPage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialLoad: (selectedCodes, enqueueSnackbar) => {
      let selectedCodeKeys = selectedCodes.map(code => { return code.key });
      OkpdService.getByParent({parentKey: "", selectedCodeKeys: selectedCodeKeys })
        .then((result) => {
          const action = {
            type: 'OKPD_INITIATED',
            OkpdCodes: result
          };
          dispatch(action);
        })
        .catch((error) => {
          enqueueSnackbar(
            'Произошла ошибка при получении списка ОКПД2',
            { 
              variant: 'error'
            });
        })
    },
    onChildNodeExpand: (node, checkedOkpdCodes, enqueueSnackbar) => {
      //не перезагружаем элемент если ниже есть выбранные узлы
      //if (node.checked !== true && node.halfChecked !== true) {
        return OkpdService.getByParent({ parentKey: node.key, selectedCodeKeys: checkedOkpdCodes.map(code => code.key)})
        .then(result => {
          const action = {
            type: 'OKPD_EXPAND_CHILD_NODE',
            parentCode: node.key,
            childrenNodes: result
          };
          dispatch(action);
        })
        .catch((error) => {
          enqueueSnackbar(
            'Произошла ошибка при получении ОКПД2',
            { 
              variant: 'error'
            });
        })
      //} else {
      //  return Promise.resolve();
      //}
    },
    onSetCheckedOkpdCodes: (checkedOkpdCodes, event) => {
      if (event.node.pos.length <= 3 && event.node.children.length === 0){
        OkpdService.getByParent({ parentKey: event.node.key, selectedCodeKeys: [] }).then(result => {
          const action = {
            type: 'OKPD_CODE_CHECKED',
            checkedOkpdNodesPositions: event.checkedNodesPositions,
            checkedNode: event.node,
            childrenNodes: result.map(code => { return code.key })
          };
          dispatch(action);
        })
      } 
      else {
        const action = {
          type: 'OKPD_CODE_CHECKED',
          checkedOkpdNodesPositions: event.checkedNodesPositions,
          checkedNode: event.node,
          childrenNodes: []
        };
        dispatch(action);
      }
    },
    onOkpdSelected: (checkedOkpdCodes, currentPage) => {
      const action = {
        type: 'OKPD_SELECTION_COMPLETED',
        checkedOkpdCodes: checkedOkpdCodes,
        currentPage: currentPage
      };
      dispatch(action);
    },
    onCloseOkpdSelection: () => {
      const action = {
        type: 'OKPD_SELECTION_CLOSED'
      };
      dispatch(action);
    },
    onInputOkpdSearch: (searchText, enqueueSnackbar) => {
      const action = {
        type: 'OKPD_SEARCH_STARTED'
      };
      dispatch(action);
      OkpdService.search(searchText)
        .then((result) => {
          const action = {
            type: 'OKPD_SEARCHED',
            searchResults: result,
            searchText: searchText
          };
          dispatch(action);
        })
        .catch(error => {
          this.props.enqueueSnackbar(
            'Произошла ошибка при поиске ОКПД2',
            { 
              variant: 'error'
            });
        })  
    },
    onOkpdExpanded: (expandedOkpdCode) => {
      const action = {
        type: 'OKPD_EXPANDED',
        expandedOkpdCode: expandedOkpdCode
      };
      dispatch(action);
    },
    onRemoveSingleOkpdSelection: (key) => {
      const action = {
        type: 'SINGLE_SELECTED_OKPD_REMOVED',
        key: key
      };
      dispatch(action);
    },
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(OkpdSelection))


const initialState = {
  currentCompany : {},
  currentUserInfo : { phone_number: '', phone_confirmed: false, email: '', email_confirmed: false, id: ''},
  activeCompanies: [],
  selectedCompanyStorageName: ''
}

const reducer = (state = initialState, action) => {
  if (action.type === 'CURRENT_COMPANY_CHANGED') {
    return {
      ...state,
      currentCompany: action.currentCompany
    }
  }

  if (action.type === 'USER_INFO_OBTAINED') {
    return {
      ...state,
      currentUserInfo: action.currentUserInfo
    }
  }

  if (action.type === 'ACTIVE_COMPANIES_OBTAINED') {
    return {
      ...state,
      activeCompanies: action.activeCompanies
    }
  }

  if (action.type === 'SELECTED_COMPANY_COOKIE_NAME_CHANGED') {
    return {
      ...state,
      selectedCompanyStorageName: action.selectedCompanyStorageName
    }
  }

  return state;
}

export default reducer;
const initialState = {
  activePurchasesCount: null,
  activeInvoicesCount: null
}

const reducer = (state = initialState, action) => {
  if (action.type === 'ACTIVE_PURCHASES_IN_WORK_LOADED') {
    return {
      ...state,
      activePurchasesInWorkCount: action.activePurchasesInWorkCount
    }
  }

  if (action.type === 'ACTIVE_INVOICES_LOADED') {
    return {
      ...state,
      activeInvoicesCount: action.activeInvoicesCount
    }
  }

  return state;
}

export default reducer;
import React from "react"
import { Typography, Grid } from "@mui/material"
import { DocumentRequest } from "./document-request";

export const DocumentRequestList = (props) => {
    let documentRequest = props.documentRequest;

    return (
        <React.Fragment>
            {
                documentRequest
                ? 
                <>
                    <Typography>{documentRequest.Comment}</Typography>
                    <div className="dropzone_grid">
                    {   documentRequest.DocumentTypes.map((document, key) => {
                            if (!document.IsDocumentReceived) {
                                return (
                                    <Grid item xs={3} key={key}>
                                        <DocumentRequest document={document} purchaseId={props.purchaseId} key={key} />
                                    </Grid>
                                );
                            }
                        }
                        )
                    }
                    </div>
                </>
                : null
            }
		</React.Fragment>
    )
}

import { Card, CardContent, Typography, Grid } from "@mui/material"
import React from "react"
import { DateTime } from 'luxon';
import { NumberFormatCustom } from '../number-format-custom';

const getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy');

    return formattedDate;
}  

export const InvoicePayments = (props) => {
    return (
        <React.Fragment>
            <div className="payment_block">
                <div className="invoice_table_header">Платежи:</div>
                <div className="payment_table">
                    {props.payments.map((payment, key) =>
                        <div className="payment_row" key={key}>
                            <div className="payment_date">
                                {getFormatDate(payment.PaymentDate)} 
                            </div>
                            <div className="payment_price">
                                <NumberFormatCustom value={payment.InitialAmount} displayType="text" /> ₽
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

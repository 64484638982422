import React, { useEffect, useState } from "react"
import { Link } from '@mui/material'; 
import { NumberFormatCustom } from '../number-format-custom';
import { OpenChatLink } from '../open-chat-link';

export const MainPageUserSummary = (props) => {
    return (
        <React.Fragment>
            {props.client
            ?
                <>
                    <div className="flx">
                        <div className="main_page_stat_section">
                            <h4 className="main_page_header">Статистика</h4>                   
                            <div className="main_page_cnt">                        
                                <div className="main_page_small_block">
                                    <div className="main_page_small_block_header">Завершено закупок</div>
                                    <div className="main_page_small_block_body">{props.client.FinishedPurchaseSessionsCount}</div>
                                </div>
                                <div className="main_page_small_block">
                                    <div className="main_page_small_block_header">Побед</div>
                                    <div className="main_page_small_block_body">{props.client.WonPurchaseSessionsCount}</div>
                                </div>
                                <div className="main_page_small_block">
                                    <div className="main_page_small_block_header">Сумма побед</div>
                                    <div className="main_page_small_block_body"><NumberFormatCustom value={props.client.TotalWonAmountSum} displayType="text" /> ₽</div>
                                </div>
                            </div>
                        </div>
                        <div className="main_page_thumb_section">
                            <h4 className="main_page_header">Кабинет партнера 1.0</h4>
                            <div className="main_page_thumb_block text_second">
                                Вы являетесь одним из первых пользователей ЛК. Нам очень важно ваше мнение и ваш комфорт при работе с ЛК.
                                При возникновении каких-либо проблем или при наличии любых пожеланий по улучшению работы 
                                с личным кабинетом, вы всегда можете <OpenChatLink text="написать"/> нам об этом и мы оперативно вам поможем.
                            </div>
                        </div>
                    </div>
                </>
            :
                null
            }
        </React.Fragment>
    )
}

{/* <div>
    <div className="main_user_contact_info">
        <div className="flx">
            {props.client.Phone && props.client.Phone.HasValue === true
            ?
                props.client.Phone.Number
            :
                null
            }
        </div>
        <div>
            {props.client.IsPhoneConfirmed === true
            ?
                'Подтвержден'
            :
                'Не подтвержден'
            }
        </div>
    </div>
    <div className="main_user_contact_info">
    <div>
            {props.client.Email && props.client.Email.HasValue === true
            ?
                props.client.Email.Address
            :
                null
            }
        </div>
        <div>
            {props.client.IsEmailConfirmed === true
            ?
                'Подтвержден'
            :
                'Не подтвержден'
            }
        </div>
    </div>
</div> */}
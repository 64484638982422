import React from "react"
import { DateTime } from 'luxon';
import { AuthenticatedLink } from "../authenticated-link";
import { InvoicePayments } from "./invoice-payments";
import { InvoiceNoPayments } from "./invoice-no-payments";
import { NumberFormatCustom } from '../number-format-custom';

const getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy');

    return formattedDate;
}

const computeSumWithDiscount = (sum, discountPercentage) => {
    return sum * ( (100 - discountPercentage) / 100);
}

const IsDateAfterCurrent = (dateStringToCompare) => {
    if (!dateStringToCompare)
    {
        return false;
    }

    const dateToCompare = new Date(dateStringToCompare);
    const currentDate = new Date();

    return currentDate <= dateToCompare;
}

export const InvoiceItem = (props) => {
    const invoice = props.invoice;

    return (
        <React.Fragment>
            <div className="one_invoice_card">
                <div className="invoice_card_header" style={ invoice.IsPaid ? { backgroundColor: "darkgreen"} : {}}>
                    <div>
                        <div className="invoice_num">
                            Счет №{invoice.No}
                        </div>
                        <div className="invoice_company">
                            {invoice.Company.AccountCompanyView.Name}
                        </div>
                    </div>
                    <div>
                        <div className="invoice_sum">
                            {
                                IsDateAfterCurrent(invoice.DiscountExpirationDate)
                                ?
                                <>
                                    Сумма<small>*</small>:
                                    <NumberFormatCustom value={ computeSumWithDiscount(invoice.Sum, invoice.DiscountPercentage) } displayType="text" /> ₽ 
                                    <s> <NumberFormatCustom value={invoice.Sum} displayType="text" /> ₽</s>
                                </>
                                :
                                <>
                                    Сумма: <NumberFormatCustom value={invoice.Sum} displayType="text" /> ₽
                                </>
                            }
                            
                        </div>
                        <div className="invoice_date">
                            Счет выставлен: {getFormatDate(invoice.Created)}
                        </div>
                    </div>
                </div>
                <div className="invoice_body">
                    <div className="invoice_info ">
                        <div className="invoice_info_block">
                            <div>
                                <div className="invoice_table_header">Документы:</div>
                                <div className="invoice_files_grid">
                                    { (invoice.InvoiceAndWacPdfFile) ?
                                        <AuthenticatedLink url={invoice.InvoiceAndWacPdfFile}>
                                            <div className="invoice_files">
                                                <div className="invoice_files_header">
                                                    Счёт и акт
                                                </div>
                                                <div className="invoice_files_list">
                                                    <img src="pdf.png" alt="pdf"/>
                                                </div>
                                            </div>
                                        </AuthenticatedLink>
                                        : null
                                    }
                                    { (invoice.ContractInvoiceAndWacPdfFile) ?
                                        <AuthenticatedLink url={invoice.ContractInvoiceAndWacPdfFile}>
                                            <div className="invoice_files">
                                                <div className="invoice_files_header">
                                                    Счёт и акт
                                                </div>
                                                <div className="invoice_files_list">
                                                    <img src="pdf.png" alt="pdf"/>
                                                </div>
                                            </div>
                                        </AuthenticatedLink>
                                        : null
                                    }
                                    { (invoice.InvoicePdfFile) ?
                                        <AuthenticatedLink url={invoice.InvoicePdfFile}>
                                            <div className="invoice_files">
                                                <div className="invoice_files_header">
                                                    Счёт
                                                </div>
                                                <div className="invoice_files_list">
                                                    <img src="pdf.png" alt="pdf"/>
                                                </div>
                                            </div>
                                        </AuthenticatedLink>
                                        : null
                                    }
                                    { (invoice.WorkAcceptanceCertificatePdfFile) ?
                                        <AuthenticatedLink url={invoice.WorkAcceptanceCertificatePdfFile}>
                                            <div className="invoice_files"> 
                                                <div className="invoice_files_header">
                                                    Акт
                                                </div>
                                                <div className="invoice_files_list">
                                                    <img src="pdf.png" alt="pdf"/>
                                                </div>
                                            </div>
                                        </AuthenticatedLink>
                                        : null
                                    }
                                    { (invoice.ContractWorkAcceptanceCertificatePdfFile) ?
                                        <AuthenticatedLink url={invoice.ContractWorkAcceptanceCertificatePdfFile}>
                                            <div className="invoice_files">
                                                <div className="invoice_files_header">
                                                    Акт
                                                </div>
                                                <div className="invoice_files_list">
                                                    <img src="pdf.png" alt="pdf"/>
                                                </div>
                                            </div>
                                        </AuthenticatedLink>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="invoice_info_block invoice_info_block_manager_and_issuer">
                            <div>
                                <div className="invoice_table_header">
                                    Менеджер:
                                </div>
                                <div className="invoice_info_block_content"> {invoice.Manager.Name ? invoice.Manager.Name : '—'}</div>
                            </div>
                            <div>
                                <div className="invoice_table_header">
                                    Выставитель:
                                </div>
                                <div className="invoice_info_block_content"> {invoice.IssuerName ? invoice.IssuerName : '—'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="invoice_payments">
                        { invoice.Payments.length > 0 
                        ?
                            <InvoicePayments payments={invoice.Payments}/>
                        :
                            <InvoiceNoPayments />
                        }
                    </div>
                </div>
                <div className="invoice_footer">
                        {
                            IsDateAfterCurrent(invoice.DiscountExpirationDate)
                            ?
                                <small className="invoice_footer_message">
                                    *Действует скидка {invoice.DiscountPercentage}% до {getFormatDate(invoice.DiscountExpirationDate)}
                                </small>
                            :
                                null
                        }
                    </div>
            </div>
        </React.Fragment>
    )
}

import React, { useEffect } from "react"
import { Modal, Button, Box } from '@mui/material';
import { connect } from 'react-redux';

const SelectCompanyModal = (props) => {
    return (
        <React.Fragment>
            <Modal
                open={props.open}
                //onClose={onClose}
            >
                <Box className="modal_body">
                    <h2 className="modal_header">Выберите компанию:</h2>
                    <div className="company_selectors">
                        {props.companies.map((company, key) => 
                            <Button 
                                variant="outlined" 
                                key={key}
                                onClick={() => props.onSelect(company)}
                            >
                                {company.Name}
                            </Button>
                        )}
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentCompany: state.profileState.currentCompany,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSelectingCurrentCompany: (selectedCompany) => {
            const action = {
                type: 'CURRENT_COMPANY_CHANGED',
                currentCompany: selectedCompany
            };
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompanyModal)
import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import UserManager from '../../userManager';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import profileService from '../../services/profileService';
import companiesService from '../../services/companiesService';

import { 
	CircularProgress, Box, Button, Grid
} from '@mui/material';

class AuthLayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true	
		};		
	}
	
	componentDidMount() {
		UserManager.getUser().then(user => {
			if (!user || user.expired) {
				UserManager.signinRedirect();
			}
			else {
				profileService.getAccountInfo()
					.then((user) => {
						if (user)
						{
							this.props.onUserInfoObtained(user);
						}
						this.setState({
							isLoading: false
						});
					})
					.catch((error) => {
						this.props.enqueueSnackbar(`Произошла ошибка при получении данных аккаунта. ${error.Message ? error.Message : ''}`, { variant: "error" });
					})

				companiesService.getUserCompanies()
					.then((result) => {
						this.props.onActiveCompaniesObtained(result);
					})
			}
		});
	}

	render() {
		if (this.state.isLoading) {
			return (
				<Box sx={{ display: 'flex' }}>
					<CircularProgress />
				</Box>
			)
		} else {
			return (
				<div>
					<Outlet />
				</div>
			)
		}
	}
}

function mapStateToProps(state) {
    return {
        currentUserInfo: state.profileState.currentUserInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUserInfoObtained: (userInfo) => {
			const action = {
				type: 'USER_INFO_OBTAINED',
				currentUserInfo: {
					phone_number: userInfo.PhoneNumber,
					phone_confirmed: userInfo.PhoneNumberConfirmed,
					email: userInfo.Email,
					email_confirmed: userInfo.EmailConfirmed,
					id: userInfo.Id
				}
			};
			dispatch(action);
        },
		onActiveCompaniesObtained: (activeCompanies) => {
			const action = {
				type: 'ACTIVE_COMPANIES_OBTAINED',
				activeCompanies: activeCompanies
			};
			dispatch(action);
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(AuthLayout))

import { SearchFilterFromCrmToSp } from '../../mappers/search-filter-mapper';

const initialState = {
  purchases: [],
  pageNumber: 0,
  pageSize: 0,
  total: 0,
  pageCount: 0,
  settlements: [],
  isLoaded: false,

  keywords: [],
  preKeywords: [],

  ignoreKeywords: [],
  preIgnoreKeywords: [],

  shouldSearchInDocuments: false,
  shouldSearchInOkpdNames: true,
  havePrepayment: false,
  haveRecommendation: false,
  datePublicationFrom: null,
  datePublicationTo: null,
  dateExpirationFrom: null,
  priceFrom: null,
  priceTo: null,
  stages: [1],
  laws: [1, 2, 3, 4],
  auctionTypes: [1, 2, 3, 4, 5, 6, 7],
  kladrCodes: [],
  customers: [],
  okpdCodes: [],
  industryCodes: [],
  shouldIncludeEmptyPrice: false,

  templates: [],
  templateId: '',
  canSaveTemplate: true,
  name: '',
  notifyDays: [],
  notifyIsEnabled: false,

  settlementsLoading: false,

  //надо будет переделывать
  openTagDialog: false,
  selectedPurchase: {},
  uploadFields: [],
  uploadPurchaseIds: [],

  sortBy: 'purchaseCreateAt',
  ascending: false,

  //избранные значения фильтра для текущего поиска - обновляются только при сбросе или новом поиске
  kladrCodesCurrentSearch: [],
  priceFromCurrentSearch: null,
  shouldIncludeEmptyPriceCurrentSearch: false,

  searchCompleted: false,

  scrollPosition: 0
}

const reducer = (state = initialState, action) => {
  if (action.type === 'RESET_SEARCH_PURCHASES') {
    return {
      ...state,
      purchases: initialState.purchases,
      pageNumber: initialState.pageNumber,
      pageSize: initialState.pageSize,
      total: initialState.total,
      pageCount: initialState.pageCount,
      settlements: initialState.settlements,
      isLoaded: initialState.isLoaded,
      customers: initialState.customers,
    
      keywords: initialState.keywords,
      preKeywords: initialState.preKeywords,
    
      ignoreKeywords: initialState.ignoreKeywords,
      preIgnoreKeywords: initialState.preIgnoreKeywords,
    
      shouldSearchInDocuments: initialState.shouldSearchInDocuments,
      shouldSearchInOkpdNames: initialState.shouldSearchInOkpdNames,
      havePrepayment: initialState.havePrepayment,
      haveRecommendation: initialState.haveRecommendation,
      datePublicationFrom: initialState.datePublicationFrom,
      datePublicationTo: initialState.datePublicationTo,
      dateExpirationFrom: initialState.dateExpirationFrom,
      priceFrom: initialState.priceFrom,
      priceTo: initialState.priceTo,
      stages: initialState.stages,
      laws: initialState.laws,
      auctionTypes: initialState.auctionTypes,
      kladrCodes: initialState.kladrCodes,
      okpdCodes: initialState.okpdCodes,
      industryCodes: initialState.industryCodes,
      shouldIncludeEmptyPrice: initialState.shouldIncludeEmptyPrice,

      kladrCodesCurrentSearch: initialState.kladrCodesCurrentSearch,
      priceFromCurrentSearch: initialState.priceFrom,
      shouldIncludeEmptyPriceCurrentSearch: initialState.shouldIncludeEmptyPriceCurrentSearch,

      templateId: initialState.templateId,
      searchCompleted: initialState.searchCompleted
    }
  }

  if (action.type === 'SEARCH_PURCHASES') {
    return {
      ...state,
      isLoaded: true,
      searchCompleted: false
    }
  }

  if (action.type === 'SEARCH_PURCHASES_FAILED') {
    return {
      ...state,
      isLoaded: false
    }
  }

  if (action.type === 'PURCHASES_FOUND') {
    return {
      ...state,
      purchases: action.purchases,
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      total: action.total,
      pageCount: action.pageCount,
      isLoaded: false,
      keywords: action.keywords,
      ignoreKeywords: action.ignoreKeywords,
      shouldSearchInDocuments: action.shouldSearchInDocuments,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames,
      havePrepayment: action.havePrepayment,
      haveRecommendation: action.haveRecommendation,
      laws: action.laws,
      datePublicationFrom: action.datePublicationFrom,
      datePublicationTo: action.datePublicationTo,
      dateExpirationFrom: action.dateExpirationFrom,
      priceFrom: action.priceFrom,
      priceTo: action.priceTo,
      stages: action.stages,
      kladrCodes: action.kladrCodes,
      customers: action.customers,
      auctionTypes: state.auctionTypes,
      okpdCodes: state.okpdCodes,
      industryCodes: state.industryCodes,
      shouldIncludeEmptyPrice: action.shouldIncludeEmptyPrice,

      kladrCodesCurrentSearch: action.kladrCodes,
      priceFromCurrentSearch: action.priceFrom,
      shouldIncludeEmptyPriceCurrentSearch: action.shouldIncludeEmptyPrice,

      searchCompleted: true
    }
  }

  if (action.type === 'SETTLEMENTS_SEARCH'){
    return {
      ...state,
      settlementsLoading: true
    }
  }

  if (action.type === 'SETTLEMENTS_SEARCH_FAILED') {
    return {
      ...state,
      settlementsLoading: false
    }
  }

  if (action.type === 'SETTLEMENTS_FOUND') {
    return {
      ...state,
      settlements: action.settlements,
      settlementsLoading: false
    }
  }

  if (action.type === 'SETTLEMENTS_RESET') {
    return {
      ...state,
      settlements: []
    }
  }

  if (action.type === 'GET_TEMPLATE') {
    return {
      ...state,
      isLoaded: false,

      keywords: action.keywords,
      preKeywords: action.keywords,

      ignoreKeywords: action.ignoreKeywords,
      preIgnoreKeywords: action.preIgnoreKeywords,

      shouldSearchInDocuments: action.shouldSearchInDocuments,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames,
      havePrepayment: action.havePrepayment,
      haveRecommendation: action.haveRecommendation,
      laws: action.laws,
      datePublicationFrom: action.datePublicationFrom,
      datePublicationTo: action.datePublicationTo,
      dateExpirationFrom: action.dateExpirationFrom,
      priceFrom: action.priceFrom,
      priceTo: action.priceTo,
      stages: action.stages,
      kladrCodes: action.kladrCodes,
      templateId: action.templateId,
      auctionTypes: action.auctionTypes,
      okpdCodes: action.okpdCodes,
      industryCodes: action.industryCodes,
      customers: action.customers,
      shouldIncludeEmptyPrice: action.shouldIncludeEmptyPrice,

      name: action.name,
      notifyIsEnabled: action.notifyIsEnabled,
      notifyDays: action.notifyDays,

      searchCompleted: false,
      purchases: []
    }
  }

  if (action.type === 'GET_TEMPLATES') {
    return {
      ...state,
      templates: action.templates
    }
  }

  if (action.type === 'KEYWORDS_CHANGES') {
    return {
      ...state,
      keywords: action.keywords.split(',')
    }
  };

  if (action.type === 'IGNORE_KEYWORDS_CHANGES') {
    return {
      ...state,
      ignoreKeywords: action.ignoreKeywords.split(',')
    }
  };

  if (action.type === 'SHOULD_SEARCH_IN_DOCUMENTS_CHANGES') {
    return {
      ...state,
      shouldSearchInDocuments: action.shouldSearchInDocuments
    }
  };

  if (action.type === 'SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES') {
    return {
      ...state,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames
    }
  };

  if (action.type === 'SHOULD_INCLUDE_EMPTY_PRICE_CHANGES') {
    return {
      ...state,
      shouldIncludeEmptyPrice: action.shouldIncludeEmptyPrice
    }
  };

  if (action.type === 'KLADR_CODES_CHANGES') {
    return {
      ...state,
      kladrCodes: action.kladrCodes
    }
  };

  if (action.type === 'CUSTOMER_CHANGES') {
    return {
      ...state,
      customers: action.customers
    }
  }

  if (action.type === 'ADD_STAGE') {
    var value = action.stageId;

    const stages = [...state.stages, value];

    return {
      ...state,
      stages: stages
    }
  }; 

  if (action.type === 'REMOVE_STAGE') {
    var value = action.stageId;
    var array = [...state.stages];
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        stages: array
      }
    }
  };

  if (action.type === 'ADD_AUCTION_TYPE') {
    var value = action.auctionTypeId;

    const auctionTypes = [...state.auctionTypes, value];

    return {
      ...state,
      auctionTypes: auctionTypes
    }
  }; 

  if (action.type === 'REMOVE_AUCTION_TYPE') {
    var value = action.auctionTypeId;
    var array = [...state.auctionTypes];
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        auctionTypes: array
      }
    }
  };

  if (action.type === 'PRICE_FROM_CHANGES') {
    return {
      ...state,
      priceFrom: action.priceFrom
    }
  };
  
  if (action.type === 'PRICE_TO_CHANGES') {
    return {
      ...state,
      priceTo: action.priceTo
    }
  };

  if (action.type === 'HAVE_PREPAYMENT_CHANGES') {
    return {
      ...state,
      havePrepayment: action.havePrepayment
    }
  };

  if (action.type === 'HAVE_RECOMMENDATION_CHANGES') {
    return {
      ...state,
      haveRecommendation: action.haveRecommendation
    }
  };  

  if (action.type === 'DATE_PUBLICATION_FROM_CHANGES') {
    return {
      ...state,
      datePublicationFrom: action.datePublicationFrom
    }
  };   

  if (action.type === 'DATE_PUBLICATION_TO_CHANGES') {
    return {
      ...state,
      datePublicationTo: action.datePublicationTo
    }
  };

  if (action.type === 'DATE_EXPIRATION_FROM_CHANGES') {
    return {
      ...state,
      dateExpirationFrom: action.dateExpirationFrom
    }
  }  

  if (action.type === 'ADD_LAW_CHANGES') {
    var value = action.lawId;

    const laws = [...state.laws, value];

    return {
      ...state,
      laws: laws
    }
  };    

  if (action.type === 'REMOVE_LAW_CHANGES') {
    var value = action.lawId;
    var array = [...state.laws];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        laws: array
      }
    }
  };

  if (action.type === 'TEMPLATE_NAME_CHANGES') {
    return {
      ...state,
      name: action.templateName
    }
  };

  if (action.type === 'TEMPLATE_EXISTING_SAVED') {
    var templates = [...state.templates];
    const templateId = action.template.id;
    const templateName = action.template.name;

    templates.forEach((template) => {
      if (templateId === template.id) {
        template.name = templateName;
      }
    })

    return {
      ...state,
      templates: templates
    }
  }

  if (action.type === 'ADD_DAY_NOTIFY') {
    var value = action.notifyDay;

    const notifyDays = [...state.notifyDays, value];

    return {
      ...state,
      notifyDays: notifyDays
    }
  }

  if (action.type === 'REMOVE_DAY_NOTIFY') {
    var value = action.notifyDay;
    var array = [...state.notifyDays];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        notifyDays: array
      }
    }
  };

  if (action.type === 'EMAIL_NOTIFY_ENABLE') {
    return {
      ...state,
      notifyIsEnabled: true
    }
  };

  if (action.type === 'EMAIL_NOTIFY_DISABLE') {
    return {
      ...state,
      notifyIsEnabled: false
    }
  };  

  if (action.type === 'CUSTOMER_RATINGS_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let customer = action.customerRatings.find(e => e.customerOrganizationId == item.customerOrganizationId);
      let rating = 0;
      if (customer) {
        rating = customer.rating;
      }
      return {
        ...item,
        customerRating: rating || 0
      }
    })    
    return {
      ...state,
      purchases: newPurchases,
    }
  }
  
  if (action.type === 'PURCHASE_TAGS_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let purchase = action.purchaseTags.find(e => e.id == item.id);
      return {
        ...item,
        tags: purchase.tags
      }
    });
    return {
      ...state,
      purchases: newPurchases
    }
  }

  if (action.type === 'PURCHASE_IN_WORK_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let isInWork = false;
      let purchase = action.purchasesInWork.find(p => p.PurchaseNumber === item.number)
      if (purchase) {
        isInWork = true;
      }
      return {
        ...item,
        isInWork: isInWork
      }
    });

    return {
      ...state, 
      purchases: newPurchases
    }
  }

  //И эти два тоже переделывать
  if (action.type === 'REFRESH_PURCHASE_TAGS') {
    const newPurchases = state.purchases.map((item, index) => {
        if (item.id === action.purchase.id){
          item.tags = action.purchase.tags
        }
        return {
          ...item
        }
    });
    return {
      ...state,
      purchases: newPurchases,
      openTagDialog: false
    }
  }

  if (action.type === 'OPEN_TAG_DIALOG') {
    return {
      ...state,
      selectedPurchase: action.selectedPurchase,
      openTagDialog: true
    }
  }

  if (action.type === 'OKPD_SELECTION_COMPLETED') {
    const updatedOkpdCodes = [...action.checkedOkpdCodes];

    return {
      ...state,
      okpdCodes: updatedOkpdCodes
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_COMPLETED') {
    const updatedIndustryCodes = [...action.checkedIndustryCodes];

    return {
      ...state,
      industryCodes: updatedIndustryCodes
    }
  }

  if (action.type === 'RESET_TEMPLATES') {
    return {
      ...initialState,
      templates: state.templates
    }
  }

  if (action.type === 'MAILING_REDIRECTED_TO_SEARCH') {
    const sp_filter = SearchFilterFromCrmToSp(action.mailing); 

    return {
      ...initialState,
      ...sp_filter
    }
  }

  if (action.type === 'SORT_BY_CHANGED') {
    const sortByObject = JSON.parse(action.value);
    return {
      ...state,
      sortBy: sortByObject.sortBy,
      ascending: sortByObject.ascending
    }
  }

  if (action.type === 'SCROLL_SAVED') {
    console.log(`scroll saved to ${action.scrollPosition}`)
    return {
      ...state,
      scrollPosition: action.scrollPosition
    }
  }

  return state;
}

export default reducer;

import React, { useState } from "react"
import { Modal, Button, Box, FormGroup, TextField, Grid, Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export const PhoneTokenInputModal = (props) => {
    const [phoneToken, setPhoneToken] = useState('');

    return (
        <React.Fragment>
            <Modal
                open={props.open}
                //onClose={onClose}
            >
                <Box sx={{...style}}>
                    <h2>На ваш номер телефона отправлена СМС с кодом</h2>
                    <Grid container sx={{mt: 2, mb: 2}}>
                        <Grid container item xs={12}>
                            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>Введите код полученный из смс для подтверждения действия:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    size="small"
                                    name="phoneVerificationToken"
                                    onChange={(event) => setPhoneToken(event.target.value) }
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" >
                            <Button
                                onClick={() => props.resendToken()}
                            >Повторно отправить код</Button>
                        </Grid>
                    </Grid>
                    <Button
                        onClick={() => props.inputToken(phoneToken)}
                    >
                        Отправить
                    </Button>
                    <Button
                        onClick={() => props.onClose()}
                        color="error"
                    >
                        Отмена
                    </Button>
                </Box>
            </Modal>
        </React.Fragment>
    )
}
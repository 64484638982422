export const MapKladrCodeFromSpToCrm = (sp_models) => {
    return sp_models.map((kladrCode) => {
        return {
            Id: kladrCode.code,
            FullRegionName: kladrCode.fullRegionName,
            Name: `${kladrCode.shortObjectType} ${kladrCode.name}`
        };
    });
}

export const MapKladrCodeFromCrmToSp = (crm_models) => {
    return crm_models.map((kladrCode) => {
        return {
            code: kladrCode.Id,
            fullRegionName: kladrCode.FullRegionName,
            name: kladrCode.Name,
            shortObjectType: ''
        }
    });
}
import React from "react"
import { Button, Box, TextField } from "@mui/material"
import { useSnackbar } from "notistack";
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { email_validation_rule } from "../../constants/validation_rules";
import { useDispatch } from "react-redux";

import settingsService from "../../services/settingsService";
import profileService from "../../services/profileService";

export const UpdateUserInfoEmail = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const handleEmailUpdate = async (formValues) => {
        const request = {
            TargetEmail: formValues.email
        };
        return settingsService.sendUpdateEmail(request)
            .then(() => {
                enqueueSnackbar('E-mail успешно изменен', { variant: "success" });
                profileService.getAccountInfo()
                    .then((userInfo) => {
                        const action = {
                            type: 'USER_INFO_OBTAINED',
                            currentUserInfo: {
                                phone_number: userInfo.PhoneNumber,
                                phone_confirmed: userInfo.PhoneNumberConfirmed,
                                email: userInfo.Email,
                                email_confirmed: userInfo.EmailConfirmed,
                                id: userInfo.Id
                            }
                        };
                        dispatch(action);
                    })
                    .catch((error) => {
                        this.props.enqueueSnackbar(`Произошла ошибка при получении данных аккаунта. ${error.Message ? error.Message : ''}`, { variant: "error" });
                    })
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            })
    }

    const validationSchema = Yup.object({
        email: email_validation_rule
            .required('Обязательное поле')
    });

    return (
        <React.Fragment>
            <Box>
                <Formik 
                    initialValues={{email: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                         handleEmailUpdate(values)
                         .finally(() => {
                             setSubmitting(false);
                             resetForm();
                         });
                    }}
                >
                { formik => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Field name="email">
                            {({field, form, meta}) => (
                                <TextField
                                    className="settings_form_input"
                                    margin="normal"
                                    name="email"
                                    label="E-mail"
                                    helperText={ meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('email')}
                                    onBlur={(event) => {
                                        //set touched only if field value changed
                                        if (meta.value !== meta.initialValue)
                                        {
                                            formik.handleBlur(event);
                                        }
                                        //reset touched if all form values pristine
                                        if (form.dirty === false)
                                        {
                                            form.setTouched({});
                                        }
                                    }}
                                    fullWidth
                                />
                            )}
                        </Field>

                        <Button sx={{mt: 2}}
                            type="submit"
                            variant="contained"
                            className="settings_form_submit"
                            disabled={formik.isSubmitting}
                        >
                                Сменить e-mail
                        </Button>
                    </Form>
                )}
                </Formik>
            </Box>
        </React.Fragment>
    )
}

import { combineReducers } from 'redux';
import invoicesReducer from './reducers/invoicesReducer'
import purchasesInWorkReducer from './reducers/purchasesInWorkReducer'
import pagesReducer from './reducers/pagesReducer'
import searchPurchasesReducer from './reducers/searchPurchasesReducer'
import okpdReducer from './reducers/okpdReducer'
import selectTagDialogReducer from './reducers/selectTagDialogReducer'
import tagsReducer from './reducers/tagsReducer'
import saveTemplateModalReducer from './reducers/saveTemplateModalReducer'
import mainMenuReducer from './reducers/mainMenuReducer'
import profileReducer from './reducers/profileReducer'
import mailingsReducer from './reducers/mailingsReducer'
import industriesReducer from './reducers/industriesReducer'

const rootReducer = combineReducers({
    invoicesState: invoicesReducer,
    purchasesInWorkState: purchasesInWorkReducer,
    pagesState: pagesReducer,
    searchPurchasesState: searchPurchasesReducer,
    okpdState: okpdReducer,
    selectTagDialogState: selectTagDialogReducer,
    tagsState: tagsReducer,
    saveTemplateModalState: saveTemplateModalReducer,
    mainMenuState: mainMenuReducer,
    profileState: profileReducer,
    mailingsState: mailingsReducer,
    industriesState: industriesReducer
});

export default rootReducer;
import React, { useState } from "react"
import { Modal, Button, Box, FormGroup, TextField, FormControlLabel, Checkbox, Autocomplete, FormHelperText } from '@mui/material';
import { FindCompanyInput } from "../find-company-input";
import { useSnackbar } from "notistack";

import companiesService from "../../services/companiesService";

export const AddCompanyModal = (props) => {
    const [newCompany, setNewCompany] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const handleAddCompany = () => {
        companiesService.addCompany(newCompany)
            .then(()=>{
                props.onSelect();
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            })
    }

    return (
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={props.onClose}
            >
                <Box className="modal_body">
                    <h2 className="modal_header">
                        Введите ИНН и выберите компанию:
                    </h2>
                    <FindCompanyInput       
                        showName={true}                 
                        onChange={(event, value) => {
                        if (value) {
                            setNewCompany({
                                "Name": value.Name,
                                "TaxPayerNumber": value.TaxPayerNumber
                            });
                        }
                        else {
                            setNewCompany("company", null);
                        }
                    }}
                    />
                    <Button 
                        variant="contained"
                        type="submit"
                        onClick={handleAddCompany}
                        sx={{mr: 2}}
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={props.onClose}
                    >
                        Отмена
                    </Button>
                </Box>
            </Modal>
        </React.Fragment>
    )
}
import React, { useState, useEffect } from "react"
import { Typography, Container, Box, CircularProgress } from "@mui/material"
import settingsService from "../../services/settingsService"
import { withRouter } from '../../withRouter';
import { useSearchParams } from 'react-router-dom';

const EmailConfirmationFromLink = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const request = {
            UserId : props.params.userId,
            Token : searchParams.get('token')
        };
        
        settingsService.confirmEmail(request)
        .then(() => {
            setMessage(`E-mail успешно подтвержден`);
        })
        .catch((error) => {
            setMessage(`Произошла ошибка при подтверждении e-mail: ${error.Message}`);
        })
        .finally(() => {
            setIsLoading(false);
        })

        //redirect to login page after 7 sec
        setTimeout(() => {
            window.location.replace('/');
            }, 7000);
    }, [])
    
    return (
        <React.Fragment>
            <Container maxWidth="md">
                {
                    isLoading
                    ?
                        <CircularProgress />
                    :
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px'
                            }}
                        >
                            <Typography>
                                {message}
                            </Typography>
                            <Typography>
                                (перенаправление на главную страницу через несколько секунд)
                            </Typography>
                        </Box>
                }
                
            </Container>
        </React.Fragment>
    )
}

export default withRouter(EmailConfirmationFromLink);

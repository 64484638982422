import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from '@mui/material';
import { Provider } from 'react-redux';
import store from './redux/store';
import { SnackbarProvider } from 'notistack';
import { Settings } from "luxon";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';

//components
import { ScrollToTop } from './scroll-to-top';

import { SigninCallback } from './components/authentication/sign-in-callback';
import { SignoutCallback } from './components/authentication/sign-out-callback';
import { SilentRenewCallback } from './components/authentication/silent-renew-callback';
import { EmailConfirmation } from './components/settings/email-confirmation';
import EmailConfirmationFromLink from './components/settings/email-confirmation-from-link';

import InvoiceIndex from './components/invoices/invoice-index';
//import { InviteCertificate } from './components/invite/invite-certificate';
import PurchaseInWorkIndex from './components/purchase-in-work/purchase-in-work-index';
import { MainPageIndex } from './components/main-page/main-page-index';
import { MainMenu } from './components/menu/main-menu';
import PurchaseSearchPanel from './components/search-purchases/PurchaseSearchPanel';
import PurchaseDetails from './components/search-purchases/PurchaseDetails';
import Tags from './components/search-purchases/Tags';
import UserSettings from './components/settings/user-settings';
import AuthLayout from './components/authentication/auth-layout';
import { CompanyOverlay } from './components/company-overlay';

import './css/style.css'; 
import { MailingsIndex } from './components/mailings/mailings-index';

//luxon settings
Settings.defaultZone = "Europe/Moscow";

document.documentElement.lang='ru';
document.documentElement.translate=false;

const theme = createTheme(
  ruRU
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <SnackbarProvider 
    autoHideDuration={7000}
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
          <Box>
            <BrowserRouter>
              {/* <ScrollToTop /> */}
              <Routes>
                <Route element={<AuthLayout />}>
                  <Route element={ <CompanyOverlay /> } >
                    <Route element={ <MainMenu /> } >
                      <Route path='' element={ <MainPageIndex /> } />
                      <Route path='search' element={ <PurchaseSearchPanel /> } />
                      <Route path='selected' element={ < Tags/> } />
                      <Route path='invoices' element={ <InvoiceIndex /> } />
                      <Route path='purchases-in-work' element={ <PurchaseInWorkIndex /> } />
                      <Route path="purchases/:purchaseId" element={<PurchaseDetails />} />
                      <Route path='settings' element= { <UserSettings /> } />
                      <Route path='mailings' element= { <MailingsIndex /> } />
                    </Route>
                  </Route>
                </Route>

                <Route path="email-confirm/:userId" element={ <EmailConfirmationFromLink /> } />
                <Route path='signin-callback' element= {<SigninCallback /> } />
                <Route path='logout-callback' element= {<SignoutCallback /> } />
                <Route path='silent-renew-callback' element= {<SilentRenewCallback /> } />
                <Route path='email-confirmation' element= { <EmailConfirmation /> } />
              </Routes>
            </BrowserRouter>
          </Box>
      </ThemeProvider>
    </Provider>
  </SnackbarProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Service from "./service";

class CompaniesService extends Service {
    async getCompany(inn) {
      if (inn.length !== 10 && inn.length !== 12) {
        return [];
      }

      let formBody = [];
      formBody.push(`Query=${inn}`);
      formBody.push(`Count=5`);
      formBody = formBody.join("&");
      
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      };

      const response = await fetch(`${process.env.REACT_APP_PORTAL_API_URI}/Suggest/Company/ByTaxPayerNumber`, requestOptions);
      if (response.status === 404){
        return [];
      }
      return response.json();
    }

    async selectCompany(company) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(company)
      }

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Registration/Stage/Second`, requestOptions);
    }

    async getUserCompanies() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const response = await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Company`, requestOptions);
      return response;
    }
    
    async addCompany(company) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(company)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Company/Add`, requestOptions);
    }

    async isCompanyAllowedToRemove(companyId) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': this.getAuthorizationToken()
        }
      };

      return await this.authenticatedFetchWithBooleanResult(`${process.env.REACT_APP_PORTAL_API_URI}/Company/IsAllowedToRemove/${companyId}`, requestOptions);
    }

    async isCompanyAllowedToEdit(companyId) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': this.getAuthorizationToken()
        }
      };

      return await this.authenticatedFetchWithBooleanResult(`${process.env.REACT_APP_PORTAL_API_URI}/Company/IsAllowedToEdit/${companyId}`, requestOptions);
    }
    
    async removeCompany(company) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(company)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Company/Remove`, requestOptions);
    }

    async removeCompanyRequest(company) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(company)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Company/Remove/Request`, requestOptions);
    }

    async editCompany(company) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(company)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Company/Edit`, requestOptions);
    }

    async editCompanyRequest(company) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(company)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Company/Edit/Request`, requestOptions);
    }
}
 
export default new CompaniesService();